import { enableProdMode, NgZone } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { hmrBootstrap } from './hmr';
import { persistState } from '@datorama/akita';
import { Consts } from '@shared/consts';
import { IVisitReportSdk } from './app/microfrontend-interfaces/IVisitReport';
import { ILabLocatorSdk } from './app/microfrontend-interfaces/ILabLocator';

if (environment.production) {
	enableProdMode();
}

const storage = persistState({ include: [Consts.ShellStoreName] });
const providers = [{ provide: 'persistStorage', useValue: storage }];

const bootstrap = () => platformBrowserDynamic(providers).bootstrapModule(AppModule);

if (environment.hmr) {
	if (module['hot']) {
		hmrBootstrap(module, bootstrap);
	} else {
		console.error('HMR is not enabled for webpack-dev-server!');
		console.log('Are you using the --hmr flag for ng serve?');
	}
} else {
	bootstrap().catch((err) => console.log(err));
}

declare global {
	interface Window {
		visitReportSdk?: IVisitReportSdk;
		labLocatorSdkFactory?: (ngZone?: NgZone) => Promise<ILabLocatorSdk>;
		pendo: any;
	}
}
