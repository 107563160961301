import { Injectable } from '@angular/core';
import { EupRoutesService } from '@core/eupRoutes.service';
import { EupHttpHandler } from '@core/eupHttpHandler.service';
import { combineLatest, defer, from, iif, Observable, of, interval, Subject } from 'rxjs';
import { map, tap, catchError, switchMap, filter, startWith, take, delay } from 'rxjs/operators';
import { TimberService } from '@logging/timber.service';
import { UUID } from 'angular2-uuid';
import { HttpHeaders } from '@angular/common/http';
import { IOSimSimulationInfo } from '../iosim-simulation.store/iosim-simulation-status-progress-state.model';
import { IOSimSimulationStatusProgressService } from '../iosim-simulation.store/iosim-simulation-status-progress.service';
import { Router } from '@angular/router';
import { CaseTypeEnum, FeatureToggle, ProcedureEnum, SoftwareOptionsForCompany } from '@shared/enums';
import { GlobalSettingsService } from '@core/globalSettings.service';
import { BaseDestroyable } from '@core/base-destroyable';
import { FeaturesToggleSettingsService } from '../../featuresToggleSettings/service/featuresToggleSettings.service';
import { SoftwareOptionsService } from '@core/softwareOptions.service';
import { takeUntil } from 'rxjs/operators';
import { DoctorPairingService } from '../doctor-pairing/doctor-pairing.service';
import { OrderSimulationInfo, SimulationStatusEnum, SimulationStatusProgress } from '@shared/iosim-plus/models/simulationStatus';
import { Order } from 'app/doctors/orders/orders.service';
import { IOSimSimulationInfoQuery } from '../iosim-simulation.store/iosim-simulation-status-progress.query';
import { EntityActions } from '@datorama/akita';
import { DeferredActionsService } from './deferred-actions.service';
import moment from 'moment';
import { IosimInfo } from '@shared/generalInterfaces';
import { ApplicationNavigationService } from '../shell/application-navigation/application-navigation.service';
import { StickyHeaderService } from '../stickyHeaderService/stickyHeader.service';

@Injectable({
	providedIn: 'root'
})
export class IOSimPlusIntegrationService extends BaseDestroyable {
	showStatusInfoModal$: Subject<IosimInfo> = new Subject<IosimInfo>();

	constructor(
		private eupRoutesService: EupRoutesService,
		private http: EupHttpHandler,
		private timberService: TimberService,
		private simulationStatusProgressService: IOSimSimulationStatusProgressService,
		private simulationStatusProgressQuery: IOSimSimulationInfoQuery,
		private doctorPairingService: DoctorPairingService,
		private router: Router,
		private globalSettingsService: GlobalSettingsService,
		private featuresToggleSettingsService: FeaturesToggleSettingsService,
		private softwareOptionsService: SoftwareOptionsService,
		private deferredActionService: DeferredActionsService,
		private applicationNavigationService: ApplicationNavigationService,
		private stickyHeaderService: StickyHeaderService
	) {
		super();
		this.initializeStatusRetrievingAfterTimeout();
	}

	private readonly requiredFeatureFlag = FeatureToggle.IOSimPlusFeatureFlag;
	private readonly defaultRetryTimeoutMs = 15000;
	static readonly defaultExpectedDuration = 300;

	isIOSimPlusAllowed$ = this.isIOSimPlusAllowed();
	isScreenshotCapturingAllowed$ = this.isScreenshotCapturingAllowed();
	isDrawingToolAllowed$ = this.isDrawingToolAllowed();
	shouldShowImageHub$ = this.shouldShowImageHub();

	static readonly allowedCaseTypes: CaseTypeEnum[] = [
		CaseTypeEnum.Invisalign,
		CaseTypeEnum.InvisalignAndiRecord
	];

	private static getHeaders(correlationId: string) {
		return new HttpHeaders({ 'X-Correlation-ID': correlationId });
	}

	openIOSimPlus(orderCode: string, orderId: string, returnUrl: string): Promise<any> {
		const correlationId = UUID.UUID();

		this.timberService.debug(`Starting IOSIM+, orderCode: ${orderCode}...`, {
			module: 'IOSimPlusIntegrationService',
			traceId: correlationId,
		});

		const iOSimLink$ = defer(() => from(
			this.router.navigate(['/', 'doctors', 'iosim-plus', orderCode, orderId], {
				queryParams: {
					returnUrl
				}
			})));

		return this.checkPairedStatus(iOSimLink$, correlationId);
	}

	startSimulation(orderId: string, orderCode: string, screen: string): Promise<any> {
		const correlationId = UUID.UUID();
		const headers = IOSimPlusIntegrationService.getHeaders(correlationId);
		const startSimulation$ = of({}).pipe(
			tap((_) => {
				this.timberService.debug('Started to start simulation.', {
					module: 'IOSimPlusIntegrationService',
					traceId: correlationId,
				});
			}),
			tap((_) => {
				this.launchSimulationStatusProgress(orderId, orderCode);
			}),
			map((_) => this.eupRoutesService.iOSimPlus.startSimulation(orderId, screen)),
			switchMap((url) => this.http.post(url, null, { headers }, false, false)),
			tap((_) => {
				this.timberService.debug('Start simulation successfully.', {
					module: 'IOSimPlusIntegrationService',
					traceId: correlationId,
				});
			}),
			catchError((error) => {
				this.timberService.error('Error while starting simulation.', {
					module: 'IOSimPlusIntegrationService',
					error: error,
					traceId: correlationId,
				});

				const simulationStatusProgress: Partial<IOSimSimulationInfo> = {
					progress: {
						simulationStatus: SimulationStatusEnum.Fail,
						startSimulationTime: undefined,
						expectedDuration: undefined
					},
					orderId,
				};

				this.dettachIOSimPlusStatusListener(orderId);
				this.simulationStatusProgressService.addOrUpdateSimulationStatusProgress(simulationStatusProgress);
				throw error;
			})
		);

		return this.checkPairedStatus(startSimulation$, correlationId);
	}

	getIOSimPlusLink(orderCode: string, correlationId: string, screen: string): Observable<string> {
		const headers = IOSimPlusIntegrationService.getHeaders(correlationId);
		return of({}).pipe(
			tap((_) => {
				this.timberService.debug(`Started to retrieve IOSimPlus link.`, {
					module: 'IOSimPlusIntegrationService',
					traceId: correlationId,
				});
			}),
			map((_) => this.eupRoutesService.iOSimPlus.getIOSimPlusLink(orderCode, screen)),
			switchMap((url) => this.http.get(url, { headers }, true, false)),
			tap((_) => {
				this.timberService.debug(`IOSimPlus link successfully retrieved.`, {
					module: 'IOSimPlusIntegrationService',
					traceId: correlationId,
				});
			}),
			catchError((error) => {
				this.timberService.error(`Error while retrieving IOSimPlus link.`, {
					module: 'IOSimPlusIntegrationService',
					error: error,
					traceId: correlationId,
				});

				throw error;
			})
		);
	}

	isIOSimPlusAllowedWithCaseType(caseTypeId: CaseTypeEnum) {
		return of(this.isCaseTypeAllowed(caseTypeId));
	}
	
	isIOSimPlusAllowedWithProcedures(procedureId: ProcedureEnum) {
		return of(procedureId === ProcedureEnum.StudyModel_iRecord);
	}
	
	isIOSimPlusAllowedAllConditions(caseTypeId: CaseTypeEnum, procedureId?: ProcedureEnum) : Observable<boolean> {
		const isIOSimPlusAllowedWithProcedures$ = procedureId !== undefined 
			? this.isIOSimPlusAllowedWithProcedures(procedureId) 
			: of(false);
	
		return combineLatest([
			this.isIOSimPlusAllowedWithCaseType(caseTypeId),
			isIOSimPlusAllowedWithProcedures$,
			this.isIOSimPlusAllowed()
		]).pipe(
			map(([isIOSimPlusAllowedWithCaseType, isIOSimPlusAllowedWithProcedures, isIOSimPlusAllowed]) => {
				return isIOSimPlusAllowed && (isIOSimPlusAllowedWithCaseType || isIOSimPlusAllowedWithProcedures);
			})
		);
	}

	attachIOSimPlusStatusListener(order: Order) {
		if (!order || !order.ioSimPlusEnabled) {
			return;
		}

		this.scheduleSimulationStatusRetrieving(order.id.toString(), order.orderCode, order.companyId, order.simulationInfo.progress);
	}

	dettachIOSimPlusStatusListener(orderId: string) {
		if (!orderId) {
			return;
		}

		this.deferredActionService.cancelAction(orderId);
	}

	dettachAllIOSimPlusStatusListeners() {
		this.deferredActionService.cancelAllActions();
	}

	launchSimulationStatusProgress(orderId: string, orderCode: string) {
		const simulationStatusProgress: Partial<IOSimSimulationInfo> = {
			progress: {
				simulationStatus: SimulationStatusEnum.InProgress,
				startSimulationTime: new Date().toUTCString(),
				expectedDuration: IOSimPlusIntegrationService.defaultExpectedDuration
			},
			orderId,
		};
		this.simulationStatusProgressService.addOrUpdateSimulationStatusProgress(simulationStatusProgress);
		const companyId = this.globalSettingsService.get().selectedCompanyId;
		this.scheduleSimulationStatusRetrieving(orderId, orderCode, companyId, simulationStatusProgress.progress);
	}

	private isCaseTypeAllowed (caseTypeId: CaseTypeEnum) {
		return IOSimPlusIntegrationService.allowedCaseTypes.includes(caseTypeId);
	}

	private isIOSimPlusAllowed(): Observable<boolean> {
		const isSoftwareOptionsAllowIOSimPlus$ = this.globalSettingsService.contextChanged.asObservable().pipe(
			startWith({}),
			map(_ => {
				const softwareOptions = this.globalSettingsService.getCurrentGlobalSettings()?.companySoftwareOptions;
				return this.checkSoftwareOptions(softwareOptions);
			})
		);

		const featureFlagEnabled$ = this.featuresToggleSettingsService
			.getFeaturesToggleSettings()
			.pipe(
				map((settings) => settings.some((flag) => flag.id === this.requiredFeatureFlag && flag.isActive))
			);

		return combineLatest([isSoftwareOptionsAllowIOSimPlus$, featureFlagEnabled$]).pipe(
			map(([swo, ff]) => swo && ff)
		);
	}

	private checkSoftwareOptions(currentSoftwareOptions: number[]): boolean {
		const isDoctorePairingEnabled = this.softwareOptionsService.isSoftwareOptionExists(SoftwareOptionsForCompany.shouldEnableDoctorPairing, currentSoftwareOptions);
		const isIOSimPlusForAllScansInMIDCEnabled = this.softwareOptionsService.isSoftwareOptionExists(SoftwareOptionsForCompany.EnableIOSimPlusForAllScansInMIDC, currentSoftwareOptions);
		const isIOSimPlusForPlusScansEnabled = this.softwareOptionsService.isSoftwareOptionExists(SoftwareOptionsForCompany.EnableIOSimPlusForPlusScans, currentSoftwareOptions);
		const isCloudConversionEnabled = this.softwareOptionsService.isSoftwareOptionExists(SoftwareOptionsForCompany.CloudConversion, currentSoftwareOptions);
		return isDoctorePairingEnabled && isCloudConversionEnabled && (isIOSimPlusForAllScansInMIDCEnabled || isIOSimPlusForPlusScansEnabled);
	}

	private checkPairedStatus<T>(observable$: Observable<T>, correlationId: string): Promise<any> {
		const performAction$ = of({}).pipe(
			tap((_) => {
				this.timberService.debug(`Doctor is paired. Trying to pair, executing action...`, {
					module: 'IOSimPlusIntegrationService',
					traceId: correlationId
				});
			}),
			switchMap(_ => observable$)
		);

		const openDoctorPairingWindow$ = of({}).pipe(
			tap((_) => {
				this.timberService.debug(`Doctor is not paired. Trying to pair, opening pairing window...`, {
					module: 'IOSimPlusIntegrationService',
					traceId: correlationId
				});
			}),
			tap(_ => this.doctorPairingService.openIdsLoginWindow()),
			switchMap(_ => this.doctorPairingService.iosimAuthenticationSuccess.asObservable()),
			take(1),
			tap((pairingStatus) => {
				this.timberService.debug(`Pairing status: ${pairingStatus}`, {
					module: 'IOSimPlusIntegrationService',
					traceId: correlationId
				});
			}),
			filter(isDoctorPaired => isDoctorPaired),
			switchMap(_ => observable$)
		);

		return this.doctorPairingService
			.pairedStatus()
			.pipe(
				switchMap(status => iif(() => status.isPaired, performAction$, openDoctorPairingWindow$)),
				take(1),
				takeUntil(this.componentAlive$)
			)
			.toPromise();
	}

	private isScreenshotCapturingAllowed(): Observable<boolean> {
		return of({}).pipe(
			switchMap(() => {
				const { selectedCompanyId } = this.globalSettingsService.get(); // get company only after subscription

				return this.softwareOptionsService.getSnapshotSoftwareOptions(selectedCompanyId);
			}),
			map(response => !response.shouldDisableCapture),
		);
	}

	private shouldShowImageHub(): Observable<boolean> {
		return of({}).pipe(
			map(() => {
				const softwareOptions = this.globalSettingsService.get()?.companySoftwareOptions;
				const result = this.softwareOptionsService.areSoftwareOptionExist([SoftwareOptionsForCompany.ImageGallery], softwareOptions);
				this.stickyHeaderService.showImageHub = result;
				return result && !this.router.url.includes('image-hub');
			})
		);
	}

	private isDrawingToolAllowed() {
		return of({}).pipe(
			switchMap(() => {
				const { selectedCompanyId } = this.globalSettingsService.get(); // get company only after subscription

				return this.softwareOptionsService.getSnapshotSoftwareOptions(selectedCompanyId);
			}),
			map(response => !response.shouldDisableDrawingTool),
		);
	}

	private initializeStatusRetrievingAfterTimeout() {
		this.simulationStatusProgressQuery.selectEntityAction(EntityActions.Update).pipe(
			map(ids => ids.map(id => this.simulationStatusProgressQuery.getEntity(id))),
			tap(statuses => {
				for (const status of statuses) {
					if (status.progress.simulationStatus > SimulationStatusEnum.InProgress) {
						this.dettachIOSimPlusStatusListener(status.orderId);
					}
				}
			}),
			takeUntil(this.componentAlive$)
		).subscribe();

		this.simulationStatusProgressQuery.selectEntityAction(EntityActions.Remove).pipe(
			map(ids => ids.map(id => this.simulationStatusProgressQuery.getEntity(id))),
			tap(statuses => {
				for (const status of statuses) {
					this.dettachIOSimPlusStatusListener(status.orderId);
				}
			}),
			takeUntil(this.componentAlive$)
		).subscribe();
	}

	private scheduleSimulationStatusRetrieving(orderId: string,
		orderCode: string, companyId: number, progress: SimulationStatusProgress) {
		if (progress.simulationStatus > SimulationStatusEnum.InProgress) {
			this.deferredActionService.cancelAction(orderId);
			return;
		}

		let timeout = moment(progress.startSimulationTime ?? new Date().toUTCString())
			.add(progress.expectedDuration ?? IOSimPlusIntegrationService.defaultExpectedDuration, 'second')
			.diff(moment.now(), 'milliseconds');

		if (timeout < 100) {
			timeout = (IOSimPlusIntegrationService.defaultExpectedDuration / 2) * 1000;
		}

		const intervalGetStatusCalls$ = interval(this.defaultRetryTimeoutMs).pipe(
			switchMap(_ => this.updateActualSimulationStatus(orderId.toString(), orderCode, companyId)));

		const subscription = of(null).pipe(
			delay(timeout),
			switchMap(_ => this.updateActualSimulationStatus(orderId.toString(), orderCode, companyId)),
			switchMap(() => intervalGetStatusCalls$)
		).subscribe();

		this.deferredActionService.scheduleAction(orderId.toString(), subscription);
	}

	private updateActualSimulationStatus(orderId: string, orderCode: string, companyId: number): Observable<any> {
		return this.getActualSimulationStatus(orderCode, companyId)
			.pipe(
				filter(result => !!result),
				map(result => {
					const progress: Partial<IOSimSimulationInfo> = {
						orderId,
						progress: {
							simulationStatus: result.simulationStatus,
							expectedDuration: result.expectedDuration,
							startSimulationTime: result.startSimulationTime
						}
					};

					return progress;
				}),
				tap(result => this.simulationStatusProgressService.addOrUpdateSimulationStatusProgress(result))
			);
	}

	private getActualSimulationStatus(orderCode: string, companyId: number): Observable<SimulationStatusProgress> {
		const correlationId = UUID.UUID();
		const headers = IOSimPlusIntegrationService.getHeaders(correlationId);

		this.timberService.debug(`Started to retrieve simulation status.`, {
			module: 'IOSimPlusIntegrationService',
			traceId: correlationId
		});

		return of(this.eupRoutesService.iOSimPlus.getSimulationStatus(orderCode, companyId))
			.pipe(
				switchMap(url => this.http.get(url, { headers }, false, false)),
				map(result => result as SimulationStatusProgress),
				tap((_) => {
					this.timberService.debug(`simulation status successfully retrieved.`, {
						module: 'IOSimPlusIntegrationService',
						traceId: correlationId
					});
				}),
				catchError((error) => {
					this.timberService.error(`Error while retrieving simulation status.`, {
						module: 'IOSimPlusIntegrationService',
						error: error,
						traceId: correlationId
					});

					return of(undefined);
				}));
	}

	getOrderSimulationInfo(orderId: number, companyId: number): Observable<OrderSimulationInfo> {
		const correlationId = UUID.UUID();
		const headers = IOSimPlusIntegrationService.getHeaders(correlationId);

		this.timberService.debug(`Started to retrieve order simulation info.`, {
			module: 'IOSimPlusIntegrationService',
			traceId: correlationId
		});

		return of(this.eupRoutesService.iOSimPlus.getExpectedDuration(orderId, companyId))
			.pipe(
				switchMap(url => this.http.get(url, { headers }, false, false)),
				map(result => result as OrderSimulationInfo),
				tap((_) => {
					this.timberService.debug(`Order simulation info successfully retrieved.`, {
						module: 'IOSimPlusIntegrationService',
						traceId: correlationId
					});
				}),
				catchError((error) => {
					this.timberService.error(`Error while retrieving order simulation info.`, {
						module: 'IOSimPlusIntegrationService',
						error: error,
						traceId: correlationId
					});

					return of(undefined);
				})
			);
	}

	iosimPlusTransition(transition: any): void {
		const correlationId = UUID.UUID();
		const navigationAction$ = defer(() => {
			this.applicationNavigationService.pushNavigationTransition(transition);
			this.applicationNavigationService.navigate(transition);
			return of(null);
		});
		
		this.checkPairedStatus(navigationAction$, correlationId);
    }
}
