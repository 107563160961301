export enum BiEventType {
    UserAction = 'UserActionType',
    UiNavigation = 'UiNavigationType',
    ErrorReport = 'ErrorReportType',
    APIReport = 'ApiReportType',
    SessionTags = 'SessionTagsType',
    SessionStart = 'SessionStartType',
};

export enum BiAction {
    Clicked = 'Clicked',
    Checked = 'Checked',
    UnChecked = 'Un-checked',
    On = 'On',
    Off = 'Off',
    Increase = 'Increase',
    Decrease = 'Decrease'
}

export enum BiLocationObject {
    Orders = 'Orders',
    PatientsOrders = 'PatientsOrders',
    WebViewer = 'WebViewer',
    NotificationsSettings = 'NotificationsSettings',
    Email = 'Email',
    Home = 'Home',
    Patients = 'Patients',
    RxContainer = 'Rx',
    Messages = 'Messages',
    PracticeManagement = 'AccountManagement',
    Education = 'LearningCenter',
    Analytics = 'Analytics'
}

export enum BiObjectType {
    Button = 'Button',
    Checkbox = 'CheckBox',
    RadioButton = 'RadioButton',
    Link = 'Link',
    Row = 'Row',
    Combobox = 'Combobox',
    Toggle = 'Toggle',
    Counter = 'Counter'
}

export enum ExistingObjectIds {
    'patients-orders-btn-view-rx' = 'ViewRxButton',
    'delivered-btn-view-rx' = 'ViewRxButton',
    'undelivered-btn-view-rx' = 'ViewRxButton',
    'delivered-btn-viewer' = 'WebViewer-Button',
    'undelivered-btn-viewer' = 'WebViewer-Button',
    'web-viewer__header-show-rx' = 'RxViewer-Button',
    'web-viewer__lab-header-show-rx' = 'RxViewer-Button',
    'web-viewer__header-show-ctm' = 'WebViewer-Button',
    'web-viewer__lab-header-show-ctm' = 'WebViewer-Button',
    'web-viewer__header-show-exocad' = 'ExocadViewer-Button',
    'patients-orders-btn-export' = 'ExportButton',
    'delivered-btn-export' = 'ExportButton',
    'undelivered-btn-export' = 'ExportButton',
    'webViewerPerformExport' = 'ExportButton',
    'takeScreenshot' = 'takeScreenshot-btn',
    'delivered-btn-download-screenshots' = 'downloadScreenshot-btn',
    'undelivered-btn-download-screenshots' = 'downloadScreenshot-btn',
    'webViewerScreenshotExport' = 'downloadScreenshot-btn',
    'patients-orders-btn-send' = 'SendButton',
    'delivered-btn-send' = 'SendButton',
    'undelivered-btn-send' = 'SendButton',
    'patients-orders-btn-export-gallery' = 'ExportGalleryButton',
    'delivered-btn-export-gallery' = 'ExportGalleryButton',
    'undelivered-btn-export-gallery' = 'ExportGalleryButton',
    'delivered-btn-itero-exocad-mini-app' = 'MyDesignButton',
    'undelivered-btn-itero-exocad-mini-app' = 'MyDesignButton',
    'patients-orders-btn-exocad-mini-app' = 'MyDesignButton',
    'patients-orders-btn-itero360' = 'itero-aohs',
    'delivered-btn-itero360' = 'itero-aohs',
    'undelivered-btn-itero360' = 'itero-aohs',
    'patients-orders-btn-add-attachments' = 'AddAttachmentsButton',
    'delivered-btn-add-attachments' = 'AddAttachmentsButton',
    'undelivered-btn-add-attachments' = 'AddAttachmentsButton',
    'delivered-btn-view-exocad' = 'ExocadViewerButton',
    'undelivered-btn-view-exocad' = 'ExocadViewerButton',
    'patients-orders-btn-itero-scan-report' = 'iTeroScanReport',
    'delivered-btn-itero-scan-report' = 'iTeroScanReport',
    'undelivered-btn-itero-scan-report' = 'iTeroScanReport',
    'subscribe-checkbox' = 'NotificationsCheckBox',
    'download-install-link' = 'DownloadInstallMyDesignApp',
    'analytics-icon-vertical-menu' = 'AnalyticsButton',
    'analytics-icon-horizontal-menu' = 'AnalyticsButton',
    'horizontal-settingsLink2' = 'Settings',
    'menu' = 'Settings',
    'practiceSettingsLink' = 'PracticeSettings',
}

export enum ApiMethods {
    Post = 'POST',
    Get = 'GET',
    Delete = 'DELETE',
    Patch = 'PATCH',
    Put = 'PUT',
}