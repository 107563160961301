import {Component, Input} from '@angular/core';

@Component({
	selector: 'eup-caret-animated',
	templateUrl: './caretAnimated.component.html',
	styleUrls: ['./caretAnimated.component.scss']
})

export class CaretAnimatedComponent {
	@Input() id: string;
	@Input()
	public upWhenTrue: boolean;
}

// Arrow - accreditation https://codepen.io/simonbreiter/pen/oLJAKy via https://freefrontend.com/css-arrows/
