import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { SettingsService, UserSettings } from '../settings.service';
import { GlobalSettingsService } from '../../core/globalSettings.service';
import { KeyValuePair } from '../../shared/generalInterfaces';
import { CaseTypeEnum, SoftwareOptionsForCompany } from '../../shared/enums';
import { Subscription } from 'rxjs';
import { DownloadNotificationService } from '../../shared/downloadNotification/downloadNotification.service';
import { DownloadFileInfo, ExportInfoDto, CreateOrthodonticsExportFileRequestDto } from '../downloadNotification/fileInfo.model';
import { ISettingsData } from '../../interfaces/ISettingsData';
import { RoleTypeEnum } from '../enums';
import { SoftwareOptionsService } from '@core/softwareOptions.service';
import { Utils } from '../utils.service';
import { BiObjectType } from '@logging/interfaces/bi.enums';


@Component({
	selector: 'eup-orthoCadExport',
	templateUrl: './orthoCadExport.component.html',
	styleUrls: [ './orthoCadExport.component.scss' ]
})
export class OrthoCadExportModal {
	@ViewChild('orthoCadExportModal') orthoCadExportModal: ModalDirective;
	caseTypeId: number;
	orderId: number;
	orderDetailsId: number;
	exportFileRequest: CreateOrthodonticsExportFileRequestDto = new CreateOrthodonticsExportFileRequestDto();
	exportTypeOptions: KeyValuePair[] = null;
	dataFormatOptions: KeyValuePair[] = null;
	fileTypeOptions: KeyValuePair[] = null;
	hidePatientName: boolean;
	selectedExportTypeOption: KeyValuePair = new KeyValuePair('', '');
	selectedDataFormatOption: KeyValuePair = new KeyValuePair('', '');
	selectedFileTypeOption: KeyValuePair = new KeyValuePair('', '');
  	exportSubscription: Subscription;
  	exportWoSubscription: Subscription;
	defaultFileType: string;
	disablePatientDetailsBySWOpiont: boolean;
	BiObjectType = BiObjectType; // Expose the enum to the template

	constructor(
		private settingsService: SettingsService,
		private globalSettings: GlobalSettingsService,
		private downloadNotificationService: DownloadNotificationService,
		private softwareOptionsService: SoftwareOptionsService,
		private utils: Utils,
		private cdr: ChangeDetectorRef
	) {}

	show(inputCaseTypeId: string, request: ExportRequest): void {
		this.cdr.detectChanges();
		this.disablePatientDetailsBySWOpiont = this.softwareOptionsService.isSoftwareOptionExists(SoftwareOptionsForCompany.shouldDisableHidePatientDetails, this.globalSettings.get().companySoftwareOptions);
		this.caseTypeId = +inputCaseTypeId;
		this.orderDetailsId = request.orderDetailsId;
		this.orderId = request.orderId;

		this.orthoCadExportModal.show();

		this.settingsService
			.getSettings(this.globalSettings.get().selectedCompanyId, true, false)
			.subscribe((settingsRes: ISettingsData) => {
				this.setExportTypeOptionsByCaseType(settingsRes);
				this.dataFormatOptions = settingsRes.dataFormatOptions.filter((o) => +o.key > 0);
				this.fileTypeOptions = settingsRes.fileTypeOptions.filter((o) => +o.key > 0);

				this.selectedExportTypeOption = new KeyValuePair('', '');
				this.selectedDataFormatOption = new KeyValuePair('', '');
				this.selectedFileTypeOption = new KeyValuePair('', '');

				this.setExportOptions(settingsRes.userSettings);

				this.hidePatientName = settingsRes.userSettings.patientAnonymization;
		});

	}

	private setExportTypeOptionsByCaseType(settings: ISettingsData): void {
		switch (this.caseTypeId) {
			case CaseTypeEnum.iCast: {
				this.exportTypeOptions = settings.exportTypeICastOptions.filter((o) => +o.key > 0);
				break;
			}
			default: {
				this.exportTypeOptions = settings.exportTypeIRecordOptions.filter((o) => +o.key > 0);
				break;
			}
		}
	}

	private setExportOptions(userSettings: UserSettings): void {
		switch (this.caseTypeId) {
			case CaseTypeEnum.iCast: {
				this.selectedExportTypeOption.key = userSettings.exportTypeForiCast.toString();
				this.selectedExportTypeOption.value =
					userSettings.exportTypeForiCast > 0
						? this.exportTypeOptions.filter(
								(o) => o.key.toString() === userSettings.exportTypeForiCast.toString()
							)[0].value
						: '';
				break;
			}
			case CaseTypeEnum.iRecord:
			case CaseTypeEnum.InvisalignAndiRecord: {
				this.selectedExportTypeOption.key = userSettings.exportTypeForiRecord.toString();
				this.selectedExportTypeOption.value =
					userSettings.exportTypeForiRecord > 0
						? this.exportTypeOptions.filter(
								(o) => o.key.toString() === userSettings.exportTypeForiRecord.toString()
							)[0].value
						: '';
				break;
			}
			default: {
				this.selectedExportTypeOption = new KeyValuePair('', '');
				break;
			}
		}

		this.selectedDataFormatOption.key = userSettings.dataFormat.toString();
		this.selectedDataFormatOption.value =
			userSettings.dataFormat > 0
				? this.dataFormatOptions.filter((o) => o.key.toString() === userSettings.dataFormat.toString())[0].value
				: '';

		this.selectedFileTypeOption.key = userSettings.fileType > 0 ? userSettings.fileType.toString() : '1';
		this.defaultFileType = this.fileTypeOptions.filter((o) => o.key.toString() === '1')[0].value;
		this.selectedFileTypeOption.value =
			userSettings.fileType > 0
				? this.fileTypeOptions.filter((o) => o.key.toString() === userSettings.fileType.toString())[0].value
				: this.defaultFileType;
	}

  export(): void {
	if (this.canExport()) {
		this.exportFileRequest.fileFormat = +this.selectedDataFormatOption.key;
		this.exportFileRequest.fileType = +this.selectedFileTypeOption.key;
		this.exportFileRequest.orderId = this.orderId;
		this.exportFileRequest.orderDetailsId = this.orderDetailsId;
		this.exportFileRequest.typeFormat = +this.selectedExportTypeOption.key;

		const settings = this.globalSettings.get();
		var requestData = this.createRequestData(settings.contactId);

		if (settings.roleType == RoleTypeEnum.Lab) {
			// check if download file ready by ideOrder work order
			this.exportWoSubscription = this.downloadNotificationService
				.getOrthodonticsExportInfoByWorkOrder(requestData)
				.subscribe((res: ExportInfoDto) => {
					if (res && res.workOrderId) {
						const fileInfo: DownloadFileInfo = {
						workOrderId: res.workOrderId,
						orderId: requestData.orderId,
						orderDetailsId: requestData.orderDetailsId,
						downloaded: true,
						requestData: requestData
						};
						this.downloadNotificationService.downloadFile(fileInfo);
					} else {
						this.downloadNotificationService.addFile(requestData);
					}
				});
		} else {
			this.exportWoSubscription = this.downloadNotificationService
				.getOrthodonticsExportInfo(requestData)
				.subscribe((res: ExportInfoDto) => {
					if (res && res.isFileExists) {
						const fileInfo: DownloadFileInfo = {
						orderId: requestData.orderId,
						orderDetailsId: requestData.orderDetailsId,
						downloaded: true,
						requestData: requestData
						};
						this.downloadNotificationService.downloadFile(fileInfo);
					} else {
						this.downloadNotificationService.addFile(requestData);
					}
				});
		}

      	this.orthoCadExportModal.hide();
    }
  }

  createRequestData(clientId: number): CreateOrthodonticsExportFileRequestDto {
	var requestData = new CreateOrthodonticsExportFileRequestDto();
	requestData.clientId = clientId;
	requestData.fileFormat = this.exportFileRequest.fileFormat;
	requestData.fileType = this.exportFileRequest.fileType;
	requestData.typeFormat = this.exportFileRequest.typeFormat;
	requestData.orderId = this.exportFileRequest.orderId;
	requestData.orderDetailsId = this.exportFileRequest.orderDetailsId;
	requestData.patientAnonymization = this.disablePatientDetailsBySWOpiont ? false : this.hidePatientName;
	var today = new Date();
	requestData.creationTime = today.toUTCString();

	return requestData;
}

  setField(fieldName: string, key: string, value: string): void {
		if (fieldName === 'ExportType') {
			this.selectedExportTypeOption.key = key;
			this.selectedExportTypeOption.value = value;
		}
		if (fieldName === 'DataFormat') {
			this.selectedDataFormatOption.key = key;
			this.selectedDataFormatOption.value = value;
		}
		if (fieldName === 'FileType') {
			this.selectedFileTypeOption.key = key;
			this.selectedFileTypeOption.value = value;
		}
	}

	canExport(): boolean {
		return (
			+this.selectedDataFormatOption.key > 0 &&
			+this.selectedExportTypeOption.key > 0 &&
			+this.selectedFileTypeOption.key > 0
		);
	}

	onHidePatientNameChanged(newVal: boolean): void {
		this.hidePatientName = newVal;
	}
}

export class ExportRequest {
	orderDetailsId: number;
	orderId: number;
}
