<div class="checkbox-wrapper" [ngClass]="{ disabled: disabled }">
    <input
        logClick
        [objectType]="BiObjectType.Checkbox"
        [action]="checked ? BiAction.UnChecked : BiAction.Checked"
        type="checkbox"
        [(ngModel)]="checked"
        (ngModelChange)="changed($event)"
        [disabled]="disabled"
        [id]="id"
        (click)="onClick($event)"/>
    <span class="checkmark"></span>
</div>