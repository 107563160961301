<div class="dropdown-wrapper" dropdown [isDisabled]="isProcessing || disabled">
    <label class="dropdown-label" *ngIf="label">{{label}}</label>
    <button logClick [objectType]="BiObjectType.Combobox" [id]="id+'-options'" dropdownToggle [ngClass]="{ 'dropdown-button-disabled' : disabled, 'dropdown-button': !disabled }" [title]="selected.value">
        <span class="content">{{selected.value}}</span>
        <span class="caret" [ngClass]="{'caret__processing': isProcessing}"></span>
    </button>
    <ng-container *ngIf="!disabled">
        <ul class="dropdown-menu" *dropdownMenu>
            <li logClick [value]="selected.value" [id]="id+'-selected'" class="dropdown-menu-item" [ngClass]="{'active':selected === item }" *ngFor="let item of items" [title]="item.value" (click)="onSelected(item)">{{item.value}}</li>
        </ul>
    </ng-container>
</div>
