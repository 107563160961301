<div class="dropdown-wrapper" dropdown>
    <label class="dropdown-label" *ngIf="label">{{label}}</label>
    <button
        logClick
        [id]="id+'-options'"
        [objectType]="BiObjectType.Combobox"
        [ngClass]="{ 'dropdown-button-disabled' : disabled, 'dropdown-button': !disabled }"
        title="{{selected.value}}"
        dropdownToggle>
        <span class="content">{{selected.value}}</span><span class="caret"></span>
    </button>
    <ng-container *ngIf="!disabled">
        <ul class="dropdown-menu" *dropdownMenu>
            <li 
                logClick
                [id]="id+'-selected'"
                [value]="item.value"
                class="dropdown-menu-item"
                [ngClass]="{'active':selected === item}"
                *ngFor="let item of items"
                title="{{item.value}}"
                (click)="onSelected(item)">{{item.value}}</li>
        </ul>
    </ng-container>
</div>