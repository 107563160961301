import { Injectable } from '@angular/core';
import { GlobalSettingsService } from '@core/globalSettings.service';
import { identify, pendo } from '@itero/itero-pendo';
import { TimberService } from '@logging/timber.service';
import { FeatureToggle } from '@shared/enums';
import { FeaturesToggleSettingsService } from 'app/featuresToggleSettings/service/featuresToggleSettings.service';
import { AppConfigService } from 'app/services/appConfig/appConfigService';
import { combineLatest } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class PendoService {
	applicatioName = 'myItero.com';
	private readonly serviceName = 'PendoService';
	constructor(
		private readonly appconfig: AppConfigService,
		private readonly featuresToggleSettingsService: FeaturesToggleSettingsService,
		private readonly timber: TimberService,
		private readonly globalSettingService: GlobalSettingsService
	) {}

	logInfoFromPendoService = (message: string) => {
		this.timber.info(message, { module: this.serviceName });
	};

	initializePendo(contactId, companyId): void {
		const pendoApiKey = this.appconfig.appSettings.pendoApiKey;
		const pendoToggle$ = this.featuresToggleSettingsService.isVisibleFeature$(FeatureToggle.PendoToggle);
		const pendoInitOnAppStart$ = this.featuresToggleSettingsService.isVisibleFeature$(FeatureToggle.PendoInitOnAppStart);

		const concatenatedObservable = combineLatest(pendoToggle$, pendoInitOnAppStart$);

		concatenatedObservable.subscribe(([isPendoAvailable, isPendoInitByDefaultForAllClients]) => {
			const companySoftwareOptions = this.globalSettingService.get()?.companySoftwareOptionsKeyValuePair;
			const pendoSwo = Object.keys(companySoftwareOptions || {})
				.filter((key) => key.startsWith(this.appconfig.appSettings.pendoFeatureFlagsPrefix))
				.map((key) => key);

			const visitorId = contactId?.toString();
			const accountId = companyId.toString();
			const pendoInstance = window.pendo;
			if (!pendoInstance)
				this.callPendo(isPendoAvailable, isPendoInitByDefaultForAllClients, pendoSwo, pendoApiKey, visitorId, accountId);
			else {
				if (pendoSwo.length > 0 || isPendoInitByDefaultForAllClients) this.identifyPendo(pendoSwo, contactId, companyId);
				else this.closePendoInstance();
			}
		});
	}

	closePendoInstance() {
		const pendoInstance = window.pendo;
		if (pendoInstance?.accountId) {
			pendoInstance.teardown();
			pendoInstance.isReady();
			delete window.pendo;
			document.querySelectorAll('script[src*="pendo"]')?.forEach((e) => e.remove());
		}
	}

	identifyPendo(pendoCswo: string[], contactId: string, companyId: string) {
		try {
			identify(pendoCswo, contactId, companyId, this.applicatioName, this.logInfoFromPendoService);
		} catch (error) {
			this.logInfoFromPendoService('Pendo service error');
		}
	}

	callPendo(
		isPendoAvailable: boolean,
		isPendoInitByDefaultForAllClients: boolean,
		pendoFeatureFlags: string[],
		pendoApiKey: string,
		visitorId: string,
		accountId: string
	): void {
		try {
			pendo(
				isPendoAvailable,
				isPendoInitByDefaultForAllClients,
				pendoFeatureFlags,
				pendoApiKey,
				visitorId,
				accountId,
				this.applicatioName,
				this.logInfoFromPendoService
			);
		} catch (error) {
			this.logInfoFromPendoService('Pendo service error');
		}
	}
}
