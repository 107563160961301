import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { EupRoutesService } from '../core/eupRoutes.service';
import {Observable, of, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

import { EupHttpHandler } from '../core/eupHttpHandler.service';
import { LocaleData, UserLanguage } from './generalInterfaces';
import { ILabCompanySettingsDto } from '../interfaces/ILabCompanySettingsDto';
import { ISettingsData } from '../interfaces/ISettingsData';
import { GlobalSettingsService } from '@core/globalSettings.service';
import { TimberService } from '@logging/timber.service';
import { TranslateService } from '@ngx-translate/core';
import { ErrorHandlerService } from 'app/errors/errorHandler.service';

@Injectable()
export class SettingsService {
	private headers = new HttpHeaders({
		'Content-Type': 'application/json'
	});
	private localeData: LocaleData;

	constructor(private http: EupHttpHandler, 
		private eupRoutesService: EupRoutesService, 
		private globalSettingsService: GlobalSettingsService,
		private timber: TimberService,
		private errorHandler: ErrorHandlerService,
		private translateService: TranslateService,) 
		{}

	getAllSecurityQuestions(): Observable<string[]> {
		return this.http.get(this.eupRoutesService.userSettings.getAllSecurityQuestions);
	}

	getVisibleUserAuthenticationDetails(userName: string): Observable<UserAuthenticationDetails> {
		return this.http.get(this.eupRoutesService.userSettings.getVisibleUserAuthenticationDetails + userName.toLowerCase());
	}

	getSettings(companyId: number, navigateOnFailure = true, triggerSpinner = true): Observable<ISettingsData> {
		const params = new HttpParams().set('companyId', companyId.toString());
		return this.http.get(
			this.eupRoutesService.userSettings.getUrl,
			{ params: params },
			navigateOnFailure,
			triggerSpinner
		);
	}

	getExportGeneralSettings(companyId: number): Observable<GeneralExportSettingsData> {
		const params = new HttpParams().set('companyId', companyId.toString());
		return this.http.get(this.eupRoutesService.userSettings.getExportGeneralSettingsUrl, { params: params });
	}

	resetAuth0UserPassword() {
		const loginEmail = this.globalSettingsService.get().loginEmail;
		const url = this.eupRoutesService.userSettings.resetPasswordUrl(loginEmail);
		return this.http.put(url).pipe(
			catchError (error => {
				this.timber.error(`error while trying to reset password, error: ${error}`, {
					module: 'LoginSettingsModal'
				});
				this.errorHandler.displayErrorMessage(
					this.translateService.instant('Errors.StandardErrorTitle'),
					this.translateService.instant('Errors.StandardErrorMessageSingle')
				);
				return throwError(() => new Error(error));
			})
		);
	}

	setSettings(key: string, value: string): Observable<any> {
		return this.http.post(this.eupRoutesService.userSettings.setUrl, { key, value }, { responseType: 'text' });
	}

	getLocalizationSettings(): Observable<LocaleData> {
		if (this.localeData) {
			return of(this.localeData);
		}
		return this.eupRoutesService.getLocalizationSettings()
			.pipe(
				map((res: any) => {
					this.localeData =  {
						languages: res.languages,
						dateFormats: res.dateFormats
					};
					return this.localeData;
				})
			);
	}

	getLocalizationSettingsByRoleType(): Observable<LocaleData> {
		if (this.localeData) {
			return of(this.localeData);
		}

		return this.http.get(this.eupRoutesService.userSettings.localizationSettingsByRoleTypeUrl)
			.pipe(
				map((res: any) => {
					this.localeData =  {
						languages: res.languages,
						dateFormats: res.dateFormats
					};
					return this.localeData;
				})
			);
	}

	setCredentials(credentials: SecurityInfoParams): Observable<boolean> {
		return this.http
			.post(this.eupRoutesService.userSettings.setCredentials, credentials, { headers: this.headers }, false)
			.pipe(
				map((res: any) => res.isSuccessful)
			);
	}

	setLabCompanySettings(companySettings: ILabCompanySettingsDto): Observable<boolean> {
		return this.http.post(
			this.eupRoutesService.userSettings.setCompanySettingsUrl,
			companySettings,
			{ headers: this.headers },
			false
		);
	}

	getLabCompanySettings(navigateOnFailure = true, triggerSpinner = true): Observable<ILabCompanySettingsDto> {
		return this.http.get(
			this.eupRoutesService.userSettings.getCompanySettingsUrl,
			null,
			navigateOnFailure,
			triggerSpinner
		);
	}

	isRxAppEnable(contactId: number, companyId: number, navigateOnFailure = true) {
		const url = this.eupRoutesService.userSettings.getIsRxAppEnableUrl(contactId, companyId);
		return this.http.get(url, undefined, navigateOnFailure);
	}
}

export class SecurityInfoParams {
	username: string;
	currentPassword: string;
	confirmPassword: string;
	newPassword: string;
	securityQuestion: string;
	securityAnswer: string;
	loginEmail: string;
}

export class GeneralExportSettingsData {
	allowRestorativeExport: boolean;
	allowOrthodonticsExport: boolean;
}

export class UserSettings {
	caseTypeId: number;
	defaultProcedureId: number;
	shadeSystemId: number;
	toothId: number;
	scanningPositionId: number;
	restorativeGuidanceOrderId: number;
	prepGuidanceOrderId: number;
	orthodonticGuidanceOrderId: number;
	scanArchFirstId: number;
	gyroOrientationId: number;
	touchPadOrientationId: number;
	scanningRangeEnableSuggestions: boolean;
	licenseNumber: string;
	signature: string;
	signatureOptionId: number;
	selectedLanguage: UserLanguage;
	dateFormat: number;
	exportTypeForiCast: number;
	exportTypeForiRecord: number;
	exportCadCamSystemType: number;
	dataFormat: number;
	fileType: number;
	niriEnabledDefault: boolean;
	allowDesktopNotifications: boolean;
	patientAnonymization: boolean;
	allowEmailNotifications: boolean;
}

export class UserAuthenticationDetails {
	userId: number;
	email: string;
	passwordQuestion: string;
}
