import { Component, ViewChild, OnDestroy, OnInit, HostListener, ChangeDetectorRef } from '@angular/core';
import { EupRoutesService } from '@core/eupRoutes.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ExocadMiniAppOptions } from './exocadMiniAppOptions';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom, Subscription, takeUntil } from 'rxjs';
import { LocalStorageService } from '@core/localStorage.service';
import { SessionInfo } from 'app/services/authentication/auth.service';
import { Consts } from '@shared/consts';
import { GlobalSettingsService } from '@core/globalSettings.service';
import { jwtDecode } from 'jwt-decode';
import { TokenRefreshService } from 'app/services/authentication/token-refresh.service';
import { ExocadMiniAppModalService } from './exocadMiniAppModal.service';
import { BaseDestroyable } from '@core/base-destroyable';
import { FeaturesToggleSettingsService } from 'app/featuresToggleSettings/service/featuresToggleSettings.service';
import { FeatureToggle } from '@shared/enums';
import { BiFeatureWrapperService } from '@logging/bi-feature-wrapper.service';
import { TimberService } from '@logging/timber.service';
import { BiLocationObject, BiObjectType } from '@logging/interfaces/bi.enums';

const closeOnBlurAfterSec = 10;
const closeAfterSec = 999999; // Do not close the dialog if it's in focus

@Component({
	selector: 'exocad-miniAppModal',
	templateUrl: './exocadMiniAppModal.component.html',
	styleUrls: ['./exocadMiniAppModal.component.scss'],
})
export class ExocadMiniAppModal extends BaseDestroyable implements OnDestroy, OnInit {
	@ViewChild('exocadMiniAppModal', { static: true }) exocadMiniAppModal: ModalDirective;
	orderId: number;
	rxId: string;
	downloadedFileName = 'iTero-Design-Suite-Downloader.exe';
	exocadInstallerUrl: string;
	formattedAppLongName: string;
	formattedAppShortName: string;
	unformattedAppShortName: string;

	private closeOnBlurTimeoutId: ReturnType<typeof setTimeout>;
	private closeAfterTimeoutId: ReturnType<typeof setTimeout>;

	exocadSubscription$: Subscription;
	contextService$: Subscription;
	BiObjectType = BiObjectType; // Expose the enum to the template

	constructor(
		private eupRoutesService: EupRoutesService,
		private translateService: TranslateService,
		private localStorageService: LocalStorageService,
		private globalSettings: GlobalSettingsService,
		private tokenRefreshService: TokenRefreshService,
		private exocadService: ExocadMiniAppModalService,
		private featuresToggleSettingsService: FeaturesToggleSettingsService,
		private biFeatureWrapperService: BiFeatureWrapperService,
		private timberService: TimberService,
		private cdr: ChangeDetectorRef
	) {
		super();
	}

	ngOnInit() {
		this.exocadMiniAppModal.config = {};
		this.exocadMiniAppModal.config.backdrop = false;
		this.exocadInstallerUrl = this.eupRoutesService.exocadMiniAppInstallerUrl;

		this.formattedAppLongName = `<b>${this.translateService.instant('Orders.MyDesignAppLongName')}</b>`;
		this.formattedAppShortName = `<b>${this.translateService.instant('Orders.MyDesignAppShortName')}</b>`;
		this.unformattedAppShortName = `${this.translateService.instant('Orders.MyDesignAppShortName')}`;
	}

	// Use 'blur' since Page Visibility API works only when switching tabs or collapsing the window, but not on Alt-Tab
	@HostListener('window:blur')
	onWindowBlur() {
		if (!this.closeAfterTimeoutId) {
			// The dialog is not visible
			return;
		}

		if (this.closeOnBlurTimeoutId) {
			// Already awaiting closing
			return;
		}

		this.closeOnBlurTimeoutId = this.closeAfter(closeOnBlurAfterSec);
	}

	ngOnDestroy() {
		this.exocadSubscription$?.unsubscribe();
		this.contextService$?.unsubscribe();
		this.clearTimeouts();
		super.ngOnDestroy();
	}

	show(options: ExocadMiniAppOptions): void {
		this.clearTimeouts();
		this.openExoConnector(options);
		this.exocadMiniAppModal.show();
	}

	openExoConnector(options: ExocadMiniAppOptions): void {
		const sessionInfo = this.localStorageService.getItemOrEmpty<SessionInfo>(Consts.Storage.SessionInfo);
		let tokenExpiring = false;

		try {
			const decodedToken = jwtDecode(sessionInfo.accessToken);

			const tokenExpiringSkew = 10000 * 60; // 10 minutes
			// Consider token expiring soon if it expires in 15 minutes or less
			tokenExpiring = decodedToken.exp && Date.now() >= decodedToken.exp * 1000 - tokenExpiringSkew;
		} catch {}

		if (!tokenExpiring) {
			this.startExoConnector(options, sessionInfo);
		} else {
			// Make sure that the token is as fresh as possible
			firstValueFrom(this.tokenRefreshService.refreshToken(sessionInfo))
				.then((_) => {
					// Get the updated token
					const sessionInfo = this.localStorageService.getItemOrEmpty<SessionInfo>(Consts.Storage.SessionInfo);

					this.startExoConnector(options, sessionInfo);
				})
				.catch(() => {
					// Do not show an error for 401 from token refresh
				});
		}
	}

	private async startExoConnector(options: ExocadMiniAppOptions, sessionInfo: SessionInfo) {
		const protocolPath = await this.getProtocolPath(options, sessionInfo);
		this.orderId = options.orderId;
		this.rxId = options.rxId;

		this.openProtocolLink(protocolPath);
	}

	public openProtocolLink(protocolPath: string): void {
		const link = document.createElement('a');
		link.setAttribute('href', protocolPath);
		document.body.appendChild(link);
		link.click();
		link.remove();
	}

	onShown() {
		this.closeAfterTimeoutId = this.closeAfter(closeAfterSec);
	}

	onHidden() {
		this.ngOnDestroy();
	}

	private closeAfter(waitSecBeforeClosing: number): ReturnType<typeof setTimeout> {
		this.clearTimeouts();

		return setTimeout(() => {
			this.exocadMiniAppModal.hide();

			this.clearTimeouts();
		}, waitSecBeforeClosing * 1000);
	}

	private clearTimeouts() {
		clearTimeout(this.closeAfterTimeoutId);
		clearTimeout(this.closeOnBlurTimeoutId);

		this.closeAfterTimeoutId = undefined;
		this.closeOnBlurTimeoutId = undefined;
	}

	private async getProtocolPath(options: ExocadMiniAppOptions, sessionInfo: SessionInfo): Promise<string> {
		const settings = this.globalSettings.get();
		let protocolPath = `exoconnector://?rxId=${options.rxId}&orderId=${options.orderId}&sessionId=${sessionInfo.sessionId}&accessToken=${sessionInfo.accessToken}&selectedCompanyId=${settings.selectedCompanyId}&selectedDoctorId=${settings.selectedDoctorId}&selectedLanguage=${settings.selectedLanguage?.code}`;

		const parametersUrl = this.eupRoutesService.exocadMiniApp.getIntegrationParameters(settings.selectedCompanyId);
		const region = this.eupRoutesService.Region;

		protocolPath += `&parametersUrl=${encodeURIComponent(parametersUrl)}`;
		protocolPath += `&region=${encodeURIComponent(region)}`;

		return protocolPath;
	}

	onMyDesignAppDownloadInstallerClick() {
		const isActive = this.featuresToggleSettingsService.isVisibleFeature(FeatureToggle.EnableExocadMiniAppInstallerSSO, false);
		if (isActive) {
			this.contextService$ = this.exocadService.context$
				.pipe(takeUntil(this.componentAlive$))
				.subscribe(([authInfo, uiContext, businessContext]) => {
					const params = {
						rxId: this.rxId,
						orderId: this.orderId,
						SESSION_ID: authInfo.sessionId,
						ACCESS_TOKEN: authInfo.accessToken,
						selectedCompanyId: businessContext.companyId,
						selectedDoctorId: businessContext.contactId,
						selectedLanguage: uiContext.language,
						region: this.eupRoutesService.Region,
						parametersUrl: this.eupRoutesService.exocadMiniApp.getIntegrationParameters(businessContext.companyId),
					};
					this.exocadSubscription$ = this.eupRoutesService
						.exocadMiniAppEndpoint(params)
						.pipe(takeUntil(this.componentAlive$))
						.subscribe((response) => {
							const responseBody = response.body;
							const responseHeader = response?.headers?.get('content-disposition');
							const url = URL.createObjectURL(responseBody);
							const a = document.createElement('a');
							a.href = url;
							a.download = this.getFileName(responseHeader);
							a.target = '_blank';
							document.body.appendChild(a);
							a.click();
							a.remove();
							URL.revokeObjectURL(url);
						});
				});
		} else {
			window.open(this.exocadInstallerUrl, '_blank');
		}
	}

	getFileName(responseHeader: string) {
		// Define regex patterns to extract both filename* (RFC 8187 encoded) and filename
		const filenameStarPattern = /filename\*\s*=\s*[^']*'[^']*'([^;]+)/i;
		const filenamePattern = /filename\s*=\s*"?(.*?)"?\s*(;|$)/i;

		// Try to match and extract filename* (UTF-8 encoded version)
		const filenameStarMatch = responseHeader?.match(filenameStarPattern);
		if (filenameStarMatch) {
			// Decode the percent-encoded UTF-8 filename
			try {
				return decodeURIComponent(filenameStarMatch[1]);
			} catch (e) {
				console.error('Error decoding filename*: ', e);
			}
		}

		// Fallback to matching regular filename
		const filenameMatch = responseHeader?.match(filenamePattern);
		if (filenameMatch) {
			return filenameMatch[1];
		}

		// If neither filename* nor filename are present, return default Name
		return this.downloadedFileName;
	}
}
