import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';
import { IPairType } from '@interfaces/IPairType';
import { WeChatPairingService } from '@doctorPairing/services/wechat-pairing.service';
import { AccountPairingStatus, MessengerType } from '@shared/enums';
import { IExternalPairStatus } from '@interfaces/IExternalPairStatus';
import { GlobalSettings, GlobalSettingsService } from '@core/globalSettings.service';
import { take, tap, finalize } from 'rxjs/operators';
import { EupRoutesService } from '@core/eupRoutes.service';
import { TimberService } from '@logging/timber.service';
import { NotificationService, ModalIcon, ModalKeys, DialogResult, ModalButton } from '@shared/notification/notification.service';
import { BaseDestroyable } from '@core/base-destroyable';
import { takeUntil } from 'rxjs/operators';
import { AppConfigService } from 'app/services/appConfig/appConfigService';

@Component({
	selector: 'eup-account-pairing-settings-modal',
	templateUrl: './accountPairingSettingsModal.component.html',
	styleUrls: ['./accountPairingSettingsModal.component.scss']
})
export class AccountPairingSettingsModalComponent extends BaseDestroyable implements OnInit, OnDestroy {

	public weChatPairType: IPairType;
	public hasSoftwareOptions: boolean;
	public pairingStatusLoaded: boolean;
	public externalPairing: IExternalPairStatus;
	public pairingStatus: AccountPairingStatus;
	public showQrCode: boolean;
	public messengerType: MessengerType;
	public companyId: number;
	public doctorId: number;
	public serverUrl: string;
	public loggerAppenderUrl: string;

	@ViewChild('accountPairingSettingsModal') accountPairingSettingsModal: ModalDirective;

	constructor(
		private accountPairingService: WeChatPairingService,
		private globalSettings: GlobalSettingsService,
		private timberService: TimberService,
		private notificationService: NotificationService,
		private eupRoutesService: EupRoutesService,
		public translateService: TranslateService,
		private appConfigService: AppConfigService,
		private cdr: ChangeDetectorRef
	) {
		super();
	}

	public toggleExternalPairing(requestForPairing: IExternalPairStatus): void {
		this.externalPairing = requestForPairing;

		if (requestForPairing.pairingRequested) {
			this.showQrCode = true;
		} else {
			this.unpairAccount();
		}
	}

	show(): void {
		this.cdr.detectChanges();
		this.accountPairingSettingsModal.show();
		this.preparePairingApp();
		this.fetchPairingStatus();
	}

	ngOnInit(): void {
		this.showQrCode = false;
		this.weChatPairType = this.getPairType();
		this.hasSoftwareOptions = true;
		this.loggerAppenderUrl = this.appConfigService.appSettings.loggingEndpoint;

		this.updateWhenLanguageChange();
	}

	closeQrCode(): void {
		this.showQrCode = false;
		this.fetchPairingStatus();
	}

	private getPairType() {
		return {
			name: 'WeChat',
			unpairedText: this.translateService.instant('Settings.weChatNotPaired'),
			pairedText: this.translateService.instant('Settings.weChatPaired'),
			statusUnknownText: this.translateService.instant('Settings.weChatStatusUnknown'),
			iconClass: 'svg-iconWechat61',
			iconWidth: '63px',
			iconHeight: '51px',
			iconText: 'WeChat'
		};
	}

	private updateWhenLanguageChange(): void {
		this.translateService.onLangChange
			.pipe(
				takeUntil(this.componentAlive$)
			)
			.subscribe(langChange => {
				this.weChatPairType = this.getPairType();
			});
	}

	private preparePairingApp() {
		const glbSettings: GlobalSettings = this.globalSettings.get();
		this.messengerType = this.accountPairingService.messengerType;
		this.doctorId = glbSettings.selectedDoctorId;
		this.companyId = glbSettings.selectedCompanyId;
		this.serverUrl = this.eupRoutesService.serverUrl;
	}

	private fetchPairingStatus(): void {
		this.pairingStatusLoaded = false;
		const settings = this.globalSettings.get();
		this.accountPairingService.getPairingStatus(settings.selectedDoctorId, settings.selectedCompanyId)
			.pipe(
				tap(pairingStatus => this.pairingStatus = pairingStatus),
				finalize(() => this.pairingStatusLoaded = true)
			).subscribe();
	}

	private unpairAccount(): void {
		this.notificationService.show(
			this.translateService.instant('AccountPairing.UnpairTitle'),
			this.translateService.instant('AccountPairing.UnpairText'),
			{
				buttonCombination: ModalKeys.YesNo,
				icon: ModalIcon.Question
			}).pipe(
				take(1)
			).subscribe((result: DialogResult) => {
				if (result.buttonPressed === ModalButton.Yes) {
					const settings = this.globalSettings.get();
					this.accountPairingService.unpairAccount(settings.selectedDoctorId, settings.selectedCompanyId)
						.subscribe(isSuccess => {
							if (!isSuccess) {
								this.timberService.error('Unpairing account failed', { module: 'AccountPairingSettingsModalComponent' });
							}
							this.fetchPairingStatus();
						});
				}
			});
	}
}
