import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { EupHttpHandler } from './eupHttpHandler.service';
import { EupRoutesService } from './eupRoutes.service';
import { GlobalSettingsService } from './globalSettings.service';
import { LocalStorageService } from './localStorage.service';
import { CookieService } from './cookie.service';
import { SpinnerService } from './spinner/spinner.service';
import { RouterInterceptorService } from './routerInterceptor.service';
import { PlatformDetectorService } from './platformDetector.service';
import { TitleService } from './title.service';
import { TlkLoadingSpinnerComponent } from '@itero/ui-toolkit-angular/loading-spinner';
import { EupToastrContainerComponent } from './toastr/toastrContainer/eupToastrContainer.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppearanceService } from './appearance.service';
import { PasswordPolicyGuard } from './passwordPolicy/passwordPolicyGuard.service';
import { PasswordPolicyPopupComponent } from './passwordPolicy/passwordPolicyPopup.component';
import { SharedModule } from '../shared/shared.module';
import { DomService } from './dom.service';
import { ExcelService } from './excel.service';
import { RolesGuard } from './roles/rolesGuard.service';
import { AuthGuard } from './auth.guard';
import { RootGuard } from './root.guard';
import { EupObserver } from './eupObserver.service';
import { OnlineOfflineService } from './onlineOffline.service';
import { BaseEventsIntegrationService } from './integration/BaseEventsIntegration';
import { ToastrModule, ToastContainerDirective } from 'ngx-toastr';

import translation from '../../translationVersion.json';
import { RegionGuard } from './region/region.guard';
import { SoftwareOptionGuard } from './software.option.gurad';
import { FeatureToggleGuard } from './feature.toggle.gurad';

export function createTranslateLoader(http: HttpClient) {
	return new TranslateHttpLoader(http, './assets/translations/', `.${translation.translationVersion}.json`);
}

@NgModule({
	imports: [
		SharedModule,
		CommonModule,
		HttpClientModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: createTranslateLoader,
				deps: [HttpClient],
			},
		}),
		ToastrModule.forRoot(),
		ToastContainerDirective,
		TlkLoadingSpinnerComponent,
	],
	declarations: [PasswordPolicyPopupComponent, EupToastrContainerComponent],
	providers: [
		EupHttpHandler,
		EupRoutesService,
		GlobalSettingsService,
		LocalStorageService,
		CookieService,
		SpinnerService,
		RouterInterceptorService,
		PlatformDetectorService,
		TitleService,
		PasswordPolicyGuard,
		DomService,
		BaseEventsIntegrationService,
		OnlineOfflineService,
		ExcelService,
		RolesGuard,
		RegionGuard,
		AppearanceService,
		AuthGuard,
		RootGuard,
		EupObserver,
		SoftwareOptionGuard,
		FeatureToggleGuard,
	],
	exports: [TlkLoadingSpinnerComponent, EupToastrContainerComponent],
})
export class CoreModule {
	constructor(
		@Optional()
		@SkipSelf()
		parentModule: CoreModule
	) {
		if (parentModule) {
			throw new Error('CoreModule is already loaded. Import it in the AppModule only');
		}
	}
}
