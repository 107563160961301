import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { IOrderInformation } from '../../interfaces/IOrderInformation';
import { BiAction, BiObjectType } from '@logging/interfaces/bi.enums';

@Component({
	selector: 'die-orders',
	templateUrl: './dieOrders.component.html',
	styleUrls: ['./dieOrders.component.scss']
})
export class DieOrdersComponent implements OnInit {
	private pDisabled: boolean;
	private backedUpValues: IOrderInformation;
	BiAction = BiAction;
	BiObjectType = BiObjectType;
	
	@Input()
	public numOfModels = 0;

	@Input()
	public additionalDies = 0;

	@Input()
	public dieDitch: boolean;

	@Input()
	public get disabled(): boolean {
		return this.pDisabled;
	}

	public set disabled(disabledStatus: boolean) {
		if (disabledStatus) {
			this.backupAllValues();
			this.resetValuesToZero();
		} else {
			this.restoreDisabledValues();
		}

		this.pDisabled = disabledStatus;
	}

	@Output()
	public emittedOrder: EventEmitter<IOrderInformation> = new EventEmitter();

	public publishOrderInformation(orderInfo?: IOrderInformation) {
		const orderInformation = orderInfo || {
			NumOfModels: this.numOfModels,
			AdditionalDies: this.additionalDies,
			DieDitch: this.dieDitch
		};

		this.emittedOrder.emit(orderInformation);
	}

	public setModelOrders(modelsNum: number): void {
		this.numOfModels = modelsNum;

		this.backupAllValues();
		this.publishOrderInformation();
	}

	public setDieOrders(ordersNum: number): void {
		this.additionalDies = ordersNum;

		this.backupAllValues();
		this.publishOrderInformation();
	}

	public setDieDitch(evt): void {
		this.dieDitch = evt.checked;

		this.backupAllValues();
		this.publishOrderInformation();
	}

	ngOnInit(): void {
		this.backedUpValues = {
			NumOfModels: this.numOfModels,
			AdditionalDies: this.additionalDies,
			DieDitch: this.dieDitch
		};
	}

	private backupAllValues(): void {
		this.backedUpValues = {
			NumOfModels: this.numOfModels,
			AdditionalDies: this.additionalDies,
			DieDitch: this.dieDitch
		};
	}

	private resetValuesToZero() {
		this.publishOrderInformation({
			NumOfModels: 0,
			AdditionalDies: 0,
			DieDitch: false
		});
	}

	private restoreDisabledValues(): void {
		this.publishOrderInformation(this.backedUpValues);
	}
}
