<div id="toastr-container-message" class="toastr-containter-class" [ngClass]="{'hidden': !showToastrsContainer}">
    <div class="toast-container-content" [ngClass]="showToastrs ? 'container-open' : 'container-closed'">
        <div class="toastr-container-message-wrapper" [ngClass]="{'showMoreNotVisible': !(showExpandableDetails$ | async)}">
            <span class="toast-container-message-icon"></span>
            <div class="toast-container-message-main-section">
                <div class="toast-container-message-main-section-content">
                    <span id="toast-container-message-text" class="toast-container-message-text">{{containerText}}</span>
                <div *ngIf="showExpandableDetails$ | async" class="toast-container-message-show-more-less" (click)="toggleToastrVisibility()">
                            <eup-caret-animated [id]="'showToastrs'" [upWhenTrue]="showToastrs"></eup-caret-animated>
                        {{showToastrs ? ('Errors.ShowLess' | translate) : ('Errors.ShowMore' | translate)}}
                    </div>  
                </div>
            </div>
            <div class="toast-container-message-close-wrapper" (click)="cancelAllToastrs()">
                    <span class="toast-container-message-close" ></span>
            </div>
        </div>
        <div class="toastr-container-errors-wrapper" [ngClass]="{'hidden': !showToastrs}">
                <div toastContainer></div>
        </div>
    </div>        
</div>