import { Injectable } from '@angular/core';
import { FeatureToggle, SoftwareOptionsForCompany } from '@shared/enums';
import { GlobalSettingsService } from '@core/globalSettings.service';
import { FeaturesToggleSettingsService } from '../featuresToggleSettings/service/featuresToggleSettings.service';
import { map } from 'rxjs/operators';
import { merge, Observable } from 'rxjs';
import { SoftwareOptionsService } from '@core/softwareOptions.service';
@Injectable({
	providedIn: 'root'
})
export class PracticeManagementAccessibilityService {
	labConnectAvailability$: Observable<boolean> = merge(
		this.featuresToggleSettingsService.getFeaturesToggleSettings(),
		this.globalSettingsService.contextChanged
	).pipe(
		map(() => {
			const labConnectFeatureToggle = this.featuresToggleSettingsService.isVisibleFeature(FeatureToggle.LabConnectTab, false);
			return this.isLabConnectVisible(labConnectFeatureToggle);
		})
	);

	accountManagementAvailability$: Observable<boolean> = merge(
		this.featuresToggleSettingsService.getFeaturesToggleSettings(),
		this.globalSettingsService.contextChanged
	).pipe(
		map(() => {
			const practiceManagementFeatureToggle = this.featuresToggleSettingsService.isVisibleFeature(FeatureToggle.PracticeManagement, false);
			const advancedPracticeManagementFeatureToggle = this.featuresToggleSettingsService.isVisibleFeature(FeatureToggle.AdvancedPracticeManagement, false);
			return this.isAccountManagement(practiceManagementFeatureToggle || advancedPracticeManagementFeatureToggle);
		})
	);

	constructor(
		private globalSettingsService: GlobalSettingsService,
		private softwareOptionsService: SoftwareOptionsService,
		private featuresToggleSettingsService: FeaturesToggleSettingsService
		) {

	}
	private isLabConnectVisible(labConnectFeatureToggle: boolean): boolean {
		const globalSettings = this.globalSettingsService.get();
		if (!globalSettings) {
			return false;
		}
		const isSwoExist = this.softwareOptionsService.isSoftwareOptionExists(
			SoftwareOptionsForCompany.LabConnectTab,
			globalSettings?.companySoftwareOptions
		);
		const currentCompany = globalSettings?.companies?.find( c => c.id === globalSettings.selectedCompanyId);

		return (labConnectFeatureToggle && isSwoExist) && !!currentCompany?.isDentalClinic && !!currentCompany?.currentUserIsAccountAdmin;
	}

	private isAccountManagement(practiceManagementFeatureToggle: boolean): boolean {
		const softwareOptions = this.globalSettingsService.get()?.companySoftwareOptions;
		if (!softwareOptions) {
			return false;
		}
		const practiceManagementSoftwareOptions = [
			SoftwareOptionsForCompany.shouldEnableVisitReportMidc,
			SoftwareOptionsForCompany.AccountManagement
		];
		const isSwoExist = this.softwareOptionsService.areSoftwareOptionExist(practiceManagementSoftwareOptions, softwareOptions);
		return practiceManagementFeatureToggle && isSwoExist;
	}
}
