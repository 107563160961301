<div bsModal #notificationsSettingsModal="bs-modal" class="eup-modal modal fade" [config]="{backdrop: 'static'}">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h3 class="modal-title">
                    <button logClick id="btn-hide-settings" type="button" class="close close-sign" (click)="notificationsSettingsModal.hide()">
                        <svg class="svg-backButton32 svg-backButton32-dims"></svg>
                    </button>
                    {{'Settings.Notifications' | translate }}
                </h3>
            </div>
            <div class="modal-body">
                <form class="form-horizontal">
                    <h3>{{'Settings.EmailNotifications' | translate }}</h3>
                    <div class="message-wrapper">
                        <div class="checkbox">
                            <eup-generic-checkbox [id]="'subscribe-checkbox'"
                                [checked]="allowEmailNotifications"
                                (checkBoxStateEmitter)="onSubscribeCheck($event)">
                            </eup-generic-checkbox>
                        </div>
                        <div>
                            <div  class="message">
                                <span id="subscribe-message-start"> {{'Settings.SubscribeToEmailNotificationsMessageStart' | translate }}</span><span id="subscribe-message-email" class="text-primary"> {{ primaryEmail }}</span> <span id="subscribe-message-end"> {{'Settings.SubscribeToEmailNotificationsMessageEnd' | translate }}</span>
                            </div>
                            
                        </div>
                    </div>
                    
                    <div id="subscribe-note" class="note">
                        {{ 'Settings.NotificationsNote' | translate }}
                    </div>
                </form>	
            </div>
        </div>
    </div>
</div>