import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, OnInit } from '@angular/core';
import {BsDropdownDirective} from 'ngx-bootstrap/dropdown';
import {DropdownComponent} from '@shared/genericUI/components/dropdown/dropdown.component';
import {TranslateService} from '@ngx-translate/core';
import {IKeyValue} from '@interfaces/IKeyValue';
import { BiObjectType } from '@logging/interfaces/bi.enums';

@Component({
	selector: 'eup-loadable-dropdown',
	templateUrl: './loadable-dropdown.component.html',
	styleUrls: [ './loadable-dropdown.component.scss' ]
})
export class LoadableDropdownComponent extends DropdownComponent implements OnChanges, OnInit {
	@Input() isProcessing = false;
	@Input() id: string;
	@Output() onSelect = new EventEmitter<IKeyValue>();
	BiObjectType = BiObjectType; // Expose the enum to the template
	
	constructor(translateService: TranslateService) {
		super(translateService);
	}
}
