<label class="radioButton-wrapper" [ngClass]="{'disabled': disabled}">
    {{itemLabel}}
    <input
        [id]="id+'-radio-btn'"
        logClick
        [objectType]="BiObjectType.RadioButton"
        type="radio"
        name="{{itemName}}"
        [disabled]="disabled"
        [value]="value"
        [checked]="checked">
    <span class="checkmark"></span>
</label> 