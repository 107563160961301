<span *ngIf="user === 'doctor'">
    <button [id]="application+'-btn'"
        logClick
        type="button"
        class="btn btn-primary"
        [class.windows-only]="windowsOnly"
        *ngIf="available"
        (click)="onClick()">
        {{title}}
    </button>
</span>

<span *ngIf="user === 'lab'">
    <ul>
        <li [id]="application+'-btn'"
            logClick
            class="menu-option"
            *ngIf="available"
            (click)="onClick()">
            <span>{{title}}</span>
        </li>
    </ul>
</span>

<eup-downloadLinkModal *ngIf="modalAllowed" id="modal-download-link" linkTextKey="Orders.LinkText" [disclaimer]="disclaimer" messageTextKey="Orders.MessageText"
[customWidth]="customWidth"></eup-downloadLinkModal>