import { Component, Input, Output, EventEmitter, forwardRef, HostBinding } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { BiAction, BiObjectType } from '@logging/interfaces/bi.enums';

@Component({
	selector: 'eup-generic-checkbox',
	templateUrl: './checkbox.component.html',
	styleUrls: ['./checkbox.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => CheckboxComponent),
			multi: true
		}
	]
})
export class CheckboxComponent implements ControlValueAccessor {
	private _ID = '';
	private _checked: boolean;
	BiObjectType = BiObjectType; // Expose the enum to the template
	BiAction = BiAction;
	
	@HostBinding('attr.id')
	externalId = '';

	@Input()
	set id(value: string) {
		this._ID = value;
		this.externalId = null;
	}

	get id() {
		return this._ID;
	}

	@Output()
	public checkBoxStateEmitter: EventEmitter<boolean> = new EventEmitter();

	@Input()
	public disabled: boolean;

	@Input()
	public set checked(value: boolean) {
		this._checked = value || false;
		this.propagateChange(this._checked);
	}

	public changed(value) {
		this.propagateTouched();
		this.checkBoxStateEmitter.emit(value);
	}

	public get checked(): boolean {
		return this._checked;
	}

	private propagateChange = (_: any) => {};
	private propagateTouched = () => {};

	writeValue(obj: any): void {
		this.checked = obj;
	}

	registerOnChange(fn: any): void {
		this.propagateChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.propagateTouched = fn;
	}

	setDisabledState?(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}

	onClick(event: Event) {
		event.stopPropagation();
	}
}
