<div *ngIf="showFlashAnimation" class="screen-shot-wrapper">
  <div [@screenShotAnimation]="animationState" 
       [ngClass]="{ 
         'flash-animation-no-drawing': !isDrawingToolAllowed,
         'flash-animation-overlay': isDrawingToolAllowed }">
    <div
      id="drawingTool-btn"
      *ngIf="isDrawingToolAllowed" 
      class="thumbnail-wrapper" 
      (click)="onOpenDrawingTool()">
      <img @screenshotThumbnailAnimation *ngIf="screenshotSrc" 
           [src]="screenshotSrc" 
           class="screenshot-thumbnail" />
      <div @pencilIconAnimation *ngIf="screenshotSrc" 
           class="pencil-icon">
          <svg class="svg-icn_pen50 svg-icn_pen50-dims"></svg>
      </div>
    </div>
  </div>
</div>