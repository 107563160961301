<div bsModal #statusInfoModal="bs-modal" class="eup-modal modal fade" [config]="{backdrop: 'static'}">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h3 class="modal-title">
                    {{'IOSimPlus.ModalTitle' | translate}}
                </h3>
            </div>
            <div class="modal-body">
                <img class="notification-icon-size alert-icon" src="/assets/images/AlertIcon.svg" alt='alert icon'>
                <div class="word-break-content message">
                    <span>{{warningContent | translate }}</span>
                </div>
            </div>
            <div class="modal-footer">
                <button logClick id="btn-close" type="button" class="btn btn-content btn-lg" (click)='hide()'>
                    {{'IOSimPlus.CloseButton' | translate}}
                </button>

                <button logClick id='retry-btn' *ngIf="isRetryable" type="button" class="btn btn-content btn-lg" (click)='retry()'>
                    {{'IOSimPlus.RetryButton' | translate}}
                </button>
            </div>
        </div>
    </div>
</div>