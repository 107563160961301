<div bsModal #confirmResetModal="bs-modal" class="eup-modal modal fade reset-password-confirm" [config]="{backdrop: 'static', keyboard: false}">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="resend-header">
                <h3 class="resend-modal-title">
                    {{ 'ResetPassword.ResetPasswordMessageTitle' | translate }}
                </h3>
            </div>
            <div class="resend-body">
                <span>{{ 'ResetPassword.ResetPasswordMessageBody' | translate }}</span>
                <span class="login-email">{{loginEmail}}</span>
            </div>
            <div class="resend-buttons">
                <input id="close" type="button" class="resend-password-button btn btn-default btn-lg" value="{{ 'ResetPassword.CloseButton' | translate }}"
                    (click)="closeConfirmationModal()" [disabled]="isSubmitting">
                <input id="resend" type="button" class="resend-password-button btn btn-default btn-lg" value="{{ 'ResetPassword.ResendButton' | translate }}"
                    (click)="resendResetPassword()" [disabled]="isSubmitting">
            </div>
        </div>
    </div>
</div>
