<main>
    <router-outlet></router-outlet>
</main>
<internal-debug></internal-debug>
<eup-toastr-container></eup-toastr-container>
<eup-notification></eup-notification>
<div class="spinner-container" *ngIf="isSpinnerActive">
    <tlk-loading-spinner [overlay]="true" />
</div>
<screenshot-toast></screenshot-toast>
