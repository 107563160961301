import { Component, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { SettingsService } from '../../shared/settings.service';
import { GlobalSettingsService } from '../../core/globalSettings.service';
import { KeyValuePair } from '../../shared/generalInterfaces';
import {ISettingsData} from '../../interfaces/ISettingsData';
import { SoftwareOptionsService } from '@core/softwareOptions.service';
import { SoftwareOptionsForCompany } from '@shared/enums';
import { BiObjectType } from '@logging/interfaces/bi.enums';

@Component({
	selector: 'eup-exportSettings',
	templateUrl: './exportSettings.component.html',
	styleUrls: [ './exportSettings.component.scss' ]
})
export class ExportSettingsModal {
	@ViewChild('exportSettingsModal') exportSettingsModal: ModalDirective;
	exportTypeICastOptions: KeyValuePair[] = null;
	selectedExportTypeICastOption: KeyValuePair = { key: '', value: '' };
	exportTypeIRecordOptions: KeyValuePair[] = null;
	selectedExportTypeIRecordOption: KeyValuePair = { key: '', value: '' };
	dataFormatOptions: KeyValuePair[] = null;
	selectedDataFormatOption: KeyValuePair = { key: '', value: '' };
	fileTypeOptions: KeyValuePair[] = null;
	selectedFileTypeOption: KeyValuePair = { key: '', value: '' };
	cadCamSystemTypes: KeyValuePair[] = null;
	selectedCadCamSystemType: KeyValuePair = { key: '', value: '' };
	defaultFileType: string;
	hidePatientName: boolean;
	disablePatientDetailsBySWOpiont: boolean;

	allowRestorativeExport = false;
	allowOrthodonticsExport = false;
	BiObjectType = BiObjectType; // Expose the enum to the template

	constructor(
		private settingsService: SettingsService,
		private globalSettings: GlobalSettingsService,
		private softwareOptionsService: SoftwareOptionsService
	) {}

	show(): void {		
		this.disablePatientDetailsBySWOpiont = this.softwareOptionsService.isSoftwareOptionExists(SoftwareOptionsForCompany.shouldDisableHidePatientDetails, this.globalSettings.get().companySoftwareOptions);
		this.exportSettingsModal.show();
		const globalSettings = this.globalSettings.get();

		this.settingsService.getSettings(globalSettings.selectedCompanyId).subscribe((res: ISettingsData) => {
			this.exportTypeICastOptions = res.exportTypeICastOptions.filter((o) => +o.key > 0);
			if (res.userSettings.exportTypeForiCast && res.userSettings.exportTypeForiCast > 0) {
				this.selectedExportTypeICastOption = new KeyValuePair(
					res.userSettings.exportTypeForiCast.toString(),
					this.exportTypeICastOptions.filter(
						(o) => o.key.toString() === res.userSettings.exportTypeForiCast.toString()
					)[0].value
				);
			}

			this.exportTypeIRecordOptions = res.exportTypeIRecordOptions.filter((o) => +o.key > 0);
			if (res.userSettings.exportTypeForiRecord && res.userSettings.exportTypeForiRecord > 0) {
				this.selectedExportTypeIRecordOption = new KeyValuePair(
					res.userSettings.exportTypeForiRecord.toString(),
					this.exportTypeIRecordOptions.filter(
						(o) => o.key.toString() === res.userSettings.exportTypeForiRecord.toString()
					)[0].value
				);
			}

			this.dataFormatOptions = res.dataFormatOptions.filter((o) => +o.key > 0);
			if (res.userSettings.dataFormat) {
				this.selectedDataFormatOption = new KeyValuePair(
					res.userSettings.dataFormat.toString(),
					this.dataFormatOptions.filter(
						(o) => o.key.toString() === res.userSettings.dataFormat.toString()
					)[0].value
				);
			}

			this.fileTypeOptions = res.fileTypeOptions.filter((o) => +o.key > 0);
			if (res.userSettings.fileType) {
				this.selectedFileTypeOption = new KeyValuePair(
					res.userSettings.fileType.toString(),
					this.fileTypeOptions.filter(
						(o) => o.key.toString() === res.userSettings.fileType.toString()
					)[0].value
				);
			}

			this.cadCamSystemTypes = res.cadCamSystemTypes.filter((o) => +o.key > 0);
			if (res.userSettings.exportCadCamSystemType && res.userSettings.exportCadCamSystemType > 0) {
				this.selectedCadCamSystemType = new KeyValuePair(
					res.userSettings.exportCadCamSystemType.toString(),
					this.cadCamSystemTypes.filter(
						(o) => o.key.toString() === res.userSettings.exportCadCamSystemType.toString()
					)[0].value
				);
			}

			this.hidePatientName = res.userSettings.patientAnonymization;
		});

		this.allowOrthodonticsExport = globalSettings.generalExportSettingsData.allowOrthodonticsExport;
		this.allowRestorativeExport = globalSettings.generalExportSettingsData.allowRestorativeExport;
	}

	setField(fieldName: string, key: string, value: string): void {
		this.settingsService.setSettings(fieldName, key).subscribe(() => {
			if (this.allowOrthodonticsExport) {
				if (fieldName === 'ExportTypeForiCast') {
					this.selectedExportTypeICastOption.key = key;
					this.selectedExportTypeICastOption.value = value;
				}
				if (fieldName === 'ExportTypeForiRecord') {
					this.selectedExportTypeIRecordOption.key = key;
					this.selectedExportTypeIRecordOption.value = value;
				}
				if (fieldName === 'DataFormat') {
					this.selectedDataFormatOption.key = key;
					this.selectedDataFormatOption.value = value;
				}
				if (fieldName === 'FileType') {
					this.selectedFileTypeOption.key = key;
					this.selectedFileTypeOption.value = value;
				}
			}
			if (this.allowRestorativeExport) {
				if (fieldName === 'ExportCadCamSystemType') {
					this.selectedCadCamSystemType.key = key;
					this.selectedCadCamSystemType.value = value;
				}
			}
		});
	}

	setBooleanField(fieldName: string, value: boolean): void {
		this.settingsService.setSettings(fieldName, value.toString()).subscribe(
			err => {
				this.settingsService.getSettings(this.globalSettings.get().selectedCompanyId).subscribe((res: ISettingsData) => {
					this.hidePatientName = res.userSettings.patientAnonymization;
				});
			});
	}

	onHidePatientNameChanged(value: boolean) {
		this.hidePatientName = value;
		this.setBooleanField('PatientAnonymization', this.hidePatientName);
	}
}
