<button class="eup-button"
        logClick
        [id]="id+'-btn'"
        (click)="useHandler(clickHandler)"
        [ngClass]="{
        'eup-button--disabled': disabled,
        'eup-button--is-processing': isProcessing,
        'lab-button-alert' : isAlert,
        'eup-button--big': size === 'big'
        }"
        [disabled]="disabled"
        [ngStyle]="{'text-align': textAlign}">
    <span class="eup-button__btn-icon" *ngIf="isIconAndText" [class]="iconClass" [ngClass]="{'eup-button__btn-icon--disabled': disabled}"></span>
    <span class="eup-button__btn-icon-margin" *ngIf="isIconAndText"></span>
    <span class="eup-button__btn-text">{{buttonText}}</span>
</button>


