<div [id]="id+'-counter'" class="item-adder" [ngClass]="{'item-adder--disabled': disabled}">
    <button
        logClick
        [objectType]="BiObjectType.Counter"
        [action]="BiAction.Decrease"        
        class="item-adder__button item-adder__decrease"
        (click)="decreaseNumber()"
        [disabled]="disabled">-</button>
    <input type="number" class="item-adder__number" min="{{minValueAllowed}}" [ngModel]="fieldNumber" (ngModelChange)="checkMinValue($event)" [disabled]="disabled" />
    <button
        logClick
        [objectType]="BiObjectType.Counter"
        [action]="BiAction.Increase"        
        class="item-adder__button item-adder__increase"
        (click)="increaseNumber()"
        [disabled]="disabled">+</button>
</div>
