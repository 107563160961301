import { ApplicationNavigationService } from 'app/services/shell/application-navigation/application-navigation.service';
import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { EupRoutesService } from '../../core/eupRoutes.service';
import { Observable, BehaviorSubject, throwError, of, forkJoin } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { GlobalSettings, GlobalSettingsService } from '../../core/globalSettings.service';
import { EupHttpHandler } from '../../core/eupHttpHandler.service';
import { ContactWithBusinessPartners } from '../../shared/generalInterfaces';
import { Params, Router } from '@angular/router';
import { AuthenticationStatus } from './models/authentication-status';
import { IOSimSimulationInfoStore } from '../iosim-simulation.store/iosim-simulation-status-progress.store';
import { Consts } from '@shared/consts';
import { DownloadNotificationService } from '@shared/downloadNotification/downloadNotification.service';
import { LogoutParameters } from './models/logout-parameters';
import { RoleTypeEnum } from '@shared/enums';
import { SpinnerService } from '@core/spinner/spinner.service';
import { ShellContextService } from '../shell-context/shell-context.service';
import { PlatformServiceNames } from '@shared/store/IShellContext';
import { CommunicationService } from '../client-communication/communication.service';
import { AppUpdater } from '@shared/appUpdater.service';
import { StickyHeaderService } from '../stickyHeaderService/stickyHeader.service';
import { PendoService } from '@shared/pendo/pendo.service';

@Injectable({
	providedIn: 'root',
})
export class AuthService {
	private appId = 'midc';
	private invalidateSessionUrl = (sessionId: string) => `${this.eupRoutesService.iTeroWebAuthApiUrl}session/invalidate?sessionId=${sessionId}&appId=${this.appId}`;

	authStatus$: BehaviorSubject<AuthenticationStatus>;

	constructor(private http: EupHttpHandler,
				private eupRoutesService: EupRoutesService,
				private globalSettingsService: GlobalSettingsService,
				private router: Router,
				private simulationStatusProgressStore: IOSimSimulationInfoStore,
				private downloadNotificationService: DownloadNotificationService,
				private spinnerService: SpinnerService,
				private shellContextService: ShellContextService,
				private communicationService: CommunicationService,
				private appUpdater: AppUpdater,
				private applicationNavigationService: ApplicationNavigationService,
				private stickyHeaderService: StickyHeaderService,
				private pendoService: PendoService
	) {
		this.authStatus$ = new BehaviorSubject<AuthenticationStatus>({ isAuthenticated: this.isAuthenticated() });
	}

	startSession(credentials: UserCredentials): Observable<any> {
		const startSessionUrl = `${this.eupRoutesService.iTeroWebAuthApiUrl}session/start?appId=${this.appId}`;
		let headers = new HttpHeaders({
			'Content-Type': 'application/json'
		});
		const startSession$ = this.http.post(startSessionUrl, { username: credentials.username, password: credentials.password }, { headers, observe: 'response' });
		const auth = () => startSession$.pipe(
			map(res => res.body),
			catchError(err => throwError(err)));

		return this.eupRoutesService.doAfterInit(auth);
	}

	proceedLoginFlow(sessionInfo: SessionInfo, credentials: UserCredentials): Observable<GlobalSettings> {
		let getUserData$: Observable<any>;
		let oldLogin$: Observable<any>;
		let headers = new HttpHeaders({
			'Content-Type': 'application/json'
		});

		localStorage.setItem(Consts.Storage.SessionInfo, JSON.stringify({accessToken: sessionInfo.accessToken, sessionId: sessionInfo.sessionId, sessionType: Consts.SessionType} as SessionInfo));
		headers = headers.set(Consts.HeaderKeys.Authorization, `Bearer ${sessionInfo.accessToken}`);
		oldLogin$ = this.http.post(this.eupRoutesService.login.loginToMidc,
			{
				username: credentials.username,
				password: credentials.password,
				sessionId: sessionInfo.sessionId
			},
			{ headers, observe: 'response' });
		getUserData$ = this.http.get(this.eupRoutesService.login.getUserData(credentials.username),
			{ headers, observe: 'response' },
			true,
			true);
			return oldLogin$.pipe(
			switchMap(_ => getUserData$),
			catchError(error => {
				if (sessionInfo.sessionId.trim() !== '') {
					return this.invalidateSession(sessionInfo.sessionId).pipe(switchMap(_ => {return throwError(error);}));
				}
				return throwError(error);
			}),
			map(userData =>userData.body),
			tap(userData => {
				this.communicationService.setSessionInfo(sessionInfo.sessionId);
				if (userData.companies && userData.companies[0]?.region) {
					this.communicationService.setRegion(userData.companies[0].region);
				}
				
				this.shellContextService.updateContext(ctx => {
					ctx.session.sessionId = sessionInfo.sessionId;
					ctx.security.accessToken = sessionInfo.accessToken;
					ctx.user.loggedInUserId = userData.contactId;
					ctx.user.roleType = userData.roleType;
					ctx.session.sessionType = Consts.SessionType;					
					this.addServiceIfNotExists(ctx, PlatformServiceNames.SessionManagementRefresh, `${this.eupRoutesService.iTeroWebAuthApiUrl}${Consts.RefreshTokenPath}`);					
					this.addServiceIfNotExists(ctx, PlatformServiceNames.AssetManager, this.eupRoutesService.assetManagerUrl);					
				});

				if (userData.dateFormat || userData.selectedLanguage?.code) {
					this.shellContextService.updateContext(ctx => {
						ctx.UI.dateFormat = userData.dateFormat;
						ctx.UI.language = userData.selectedLanguage?.code || 'en-US';			
					});
				}
			})
		);
	}

	private addServiceIfNotExists(ctx, serviceName, serviceUrl){
		if(!ctx.platformServices.some(x => x.name == serviceName)) 
			ctx.platformServices.push({name: serviceName, url: serviceUrl});
	}

	login(credentials: UserCredentials): Observable<any> {
		let headers = new HttpHeaders({
			'Content-Type': 'application/json'
		});
		const startLogin$ = this.http.post(this.eupRoutesService.login.url,{ username: credentials.username, password: credentials.password },{ headers, observe: 'response' })
		const auth = () => startLogin$.pipe(
			map(res => res.body));

		return this.eupRoutesService.doAfterInit(auth);
	}

	impersonate(user: ImpersonateUserCredentials): Observable<GlobalSettings> {
		const headers = new HttpHeaders({
			'Content-Type': 'application/json'
		});

		const auth = () => this.http.post(
			this.eupRoutesService.logonAs.url,
			{ contactId: user.contactId, businessPartnerId: user.businessPartnerId, sessionId: user.sessionId },
			{ headers, observe: 'response' }
		)
		.pipe(
			map((res: any) => {
				if (res.hasOwnProperty('body') && Object.keys(res.body).length > 0) {
					this.authStatus$.next({ isAuthenticated: true });
					return res.body as GlobalSettings;
				} else {
					// in case we want to redirect the user to another route, we need to get an error in order to make unsubscribe the login subscription
					return res.json();
				}
			})
		);

		return this.eupRoutesService.doAfterInit(auth);
	}

	getContactWithBusinessPartners(userName: string): Observable<ContactWithBusinessPartners[]> {
		const params = new HttpParams().set('contactName', userName);

		return this.http.get(this.eupRoutesService.logonAs.getContacts, { params: params }, null, false);
	}

	getContactWithBusinessPartnersById(contactId: number): Observable<ContactWithBusinessPartners[]> {
		const params = new HttpParams().set('contactId', contactId);

		return this.http.get(this.eupRoutesService.logonAs.getContactsById, { params: params }, null, false);
	}

	getDetails(): Observable<LoginDetails> {
		const getLogin = () => this.http.get(this.eupRoutesService.getLoginData(), undefined, undefined, false);
		return this.eupRoutesService.doAfterInit(getLogin, true);
	}

	isAuthenticated(): boolean {
		const settings = this.globalSettingsService.get();
		return !!settings;
	}

	logoutClient(params: LogoutParameters = {}): void {
		try{
		const queryParams: Params = { returnUrl: params.returnUrl };
		const userId = this.globalSettingsService.get()?.contactId;
		this.globalSettingsService.clear();
		this.authStatus$.next(
			{ 
			 isAuthenticated: false,
			 isUnauthenticatedByTimeout: params.byTimeout,
			 userId
			});
		this.downloadNotificationService.clear();
		this.simulationStatusProgressStore.reset();
		this.spinnerService.stop();
		this.router.navigate(['/login'], { queryParams });
		}
		finally
		{
		  this.appUpdater.checkForUpdates();
		}
	}

	logout(sessionId: string = '', params: LogoutParameters = {}): Observable<any> {
		const sessionInfoFromStorage = localStorage.getItem(Consts.Storage.SessionInfo);
		const sessionInfo = sessionInfoFromStorage ? JSON.parse(sessionInfoFromStorage) as SessionInfo : undefined;
		const logoutUrl = this.globalSettingsService.get()?.roleType === RoleTypeEnum.Lab ? this.eupRoutesService.lab.logout : this.eupRoutesService.home.logout;

		const invalidate$ = 
			sessionInfo && sessionInfo.sessionId.trim() !== '' && 
			(!sessionId || (sessionId && sessionInfo.sessionId.trim() === sessionId.trim())) ?
				this.invalidateSession(sessionInfo.sessionId) : of({});

		this.shellContextService.resetContext();
		this.applicationNavigationService.resetTransitionStack();
		this.stickyHeaderService.resetLogo();
		this.pendoService.closePendoInstance();
		return forkJoin([this.http.postWithoutCancel(logoutUrl).pipe(catchError(() => {
			return of({});
		})), invalidate$.pipe(catchError(() => {
			return of({});
		}))]).pipe(
			map(_ => this.logoutClient(params))
		);
    }
	
	invalidateSession(sessionId: string): Observable<boolean> {
		const headers = new HttpHeaders({
			'Content-Type': 'application/json'
		});
		return this.http
			.deleteWithoutCancel(this.invalidateSessionUrl(sessionId), { headers, observe: 'response' })
			.pipe(
				map((res: any) => {
					return res.body;
				}),
				catchError(error => {
					return of(false);
				})
			);
	}
}

export class UserCredentials {
	username: string;
	password: string;

	constructor(username: string, password: string) {
		this.username = username;
		this.password = password;
	}
}

export interface SessionInfo {
	sessionId: string;
	accessToken: string;
	sessionType: string;
}

export class ImpersonateUserCredentials {
	contactId: number;
	businessPartnerId: number;
	sessionId?: string;

	constructor(contactId: number, businessPartnerId: number, sessionId?: string) {
		this.businessPartnerId = businessPartnerId;
		this.contactId = contactId;
		this.sessionId = sessionId;
	}
}

export class LoginDetails {
	appVersion: string;
	bffVersion: string;
	databaseVersion: string;
	forgotPasswordUrl: string;
	dbInfo: string;
	region: string;
}
