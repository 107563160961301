export class KnownComponentsNames {
	public static readonly WebViewer: string = 'webViewer';
	public static readonly PastOrdersComponent: string = 'pastOrders';
	public static readonly PatientOrdersComponent: string = 'patientOrders';
	public static readonly AohsComponent: string = 'itero-aohs';
	public static readonly OrdersPage: string = 'orders-page';
	public static readonly IosimPlus: string = 'iosimplus';
	public static readonly UnknownComponent: string = 'unknown';
	public static readonly ImageHub = 'image-hub';
}
