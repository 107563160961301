import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BaseDestroyable } from '@core/base-destroyable';
import { KnownComponentsNames } from '@core/components-names';
import { PlatformCommunicationEvents } from '@shared/generalInterfaces';
import { PostMessageService } from '@shared/post-message.service';
import { CaptureSupportService } from 'app/doctors/iosimPlus/services/capture-support.service';
import { PlatformCommunicationService } from 'app/platform/services/platform-communication/platform-communication.service';
import { ShellContextService } from 'app/services/shell-context/shell-context.service';
import { StickyHeaderService } from 'app/services/stickyHeaderService/stickyHeader.service';
import { of, switchMap, takeUntil, tap, take, filter, distinctUntilChanged, Subscription } from 'rxjs';

@Component({
	selector: 'eup-screenshot-manager',
	templateUrl: './screenshot-manager.component.html',
	styleUrl: './screenshot-manager.component.scss',
})
export class ScreenshotManagerComponent extends BaseDestroyable implements OnInit, OnDestroy {
	private readonly animationTimeWithoutThumbnail = 300;
	private readonly animationTimeWithThumbnail = 8000;

	@Input() isDrawingToolAllowed: boolean;

	capturedImageData: object;
	showDrawingTool: boolean;
	showFlashAnimation: boolean = false;
	captureAnimationTimeOut: number;
	screenshotSrc: string = '';
	orderId: string;
	page: KnownComponentsNames;
	userClickedToOpenDrawingTool = false;

	private abortSaveScreenshot = false;
	initSub: Subscription;

	constructor(
		private platformCommunicationService: PlatformCommunicationService,
		private stickyHeaderService: StickyHeaderService,
		private captureSupportService: CaptureSupportService,
		private shellContextService: ShellContextService,
		private router: Router,
		private postMessageService: PostMessageService,
	) {
		super();
	}

	ngOnInit(): void {
		this.showDrawingTool = false;
		this.initSub = this.shellContextService
			.getOrder()
			.pipe(
				takeUntil(this.componentAlive$),
				filter((order) => !!order),
				tap((order) => (this.orderId = order.id?.toString())),
				switchMap((_) => {
					return this.stickyHeaderService.screenshotClicked$;
				}),
				filter((result) => !!result),
				switchMap(() => {
					this.publishScreenshotClickEvent();
					return this.stickyHeaderService.screenshotsResult$;
				}),
				filter((result) => !!result),
				distinctUntilChanged(),
				switchMap((data) => {
					if (this.captureSupportService.isScreenShotsDataValid(data)) {
						return of(this.prepareSaveScreenshot(data));
					} else {
						return this.captureSupportService.showError(data, this.page);
					}
				})
			)
			.subscribe();
	}
		
	override ngOnDestroy(): void {
		this.initSub.unsubscribe();
		this.captureAnimationTimeOut && clearTimeout(this.captureAnimationTimeOut);
		this.stickyHeaderService.resetSubjects();
		super.ngOnDestroy();
	}

	onOpenDrowingTool() {
		this.abortSaveScreenshot = true;
		this.showFlashAnimation = false;
		
		if (this.stickyHeaderService.showImageHub) {
			this.router.navigate(['mfe-wrapper','image-hub', this.orderId, 'images', 'new'], { state: { data: this.capturedImageData } });

		} else {
			this.showDrawingTool = true;
		}
	}

	onImageLoaded(capturedImage: object) {
		this.showDrawingTool = false;
		this.capturedImageData = null;
		this.saveScreenshot(capturedImage);
	}

	private publishScreenshotClickEvent() {
		this.setPage();
		this.showFlashAnimation = false;
		clearTimeout(this.captureAnimationTimeOut);
		switch (this.page) {
			case KnownComponentsNames.AohsComponent:
				this.platformCommunicationService.publishEvent(PlatformCommunicationEvents.ScreenshotClicked, ['*'], undefined);
				break;
			case KnownComponentsNames.WebViewer:
				this.postMessageService.send('web-3d-tool-iframe', { key: 'TAKE_SCREENSHOTS' });
				break;
			case KnownComponentsNames.IosimPlus:
				{
					// const iframe = this.iosimPlusIFrame.nativeElement;
					// const takeScreenshotCommand = {
					// 	orderId: this.orderId,
					// 	key: PostMessageKeyType.TakeScreenshotResults,
					// };
					// this.postMessageService.sendToIFrame(iframe, takeScreenshotCommand);
				}
				break;
			default:
				break;
		}
	}

	private setPage() {
		if (this.router.url.includes(KnownComponentsNames.AohsComponent)) {
			this.page = KnownComponentsNames.AohsComponent;
		} else if (this.router.url.includes('viewer')) {
			this.page = KnownComponentsNames.WebViewer;
		} else {
			this.page = KnownComponentsNames.IosimPlus;
		}
	}

	private prepareSaveScreenshot(data: any) {
		this.abortSaveScreenshot = false;
		this.createScreenshotSrc(data);
		this.showFlashAnimation = true;
		this.capturedImageData = data;
		this.captureAnimationTimeOut = window.setTimeout(() => {
			if (!this.abortSaveScreenshot) {
				this.saveScreenshot(data);
			}
			this.showFlashAnimation = false;
		}, this.getCaptureAnimationTime());
	}

	private saveScreenshot(data) {
		switch (this.page) {
			case KnownComponentsNames.AohsComponent:
			case KnownComponentsNames.WebViewer:
				this.captureSupportService.saveScreenShotNotIosim(data, this.orderId).pipe(
					takeUntil(this.componentAlive$),
					take(1))
					.subscribe();
				break;
			case KnownComponentsNames.IosimPlus:
				this.captureSupportService.saveScreenShot(data, this.orderId).pipe(
					takeUntil(this.componentAlive$),
					take(1))
					.subscribe();
				break;
			default:
				break;
		}
		this.stickyHeaderService.callNext('downloadScreenshotsEnabled$', true);
	}

	private getCaptureAnimationTime() {
		return this.isDrawingToolAllowed ? this.animationTimeWithThumbnail : this.animationTimeWithoutThumbnail;
	}

	private createScreenshotSrc(data) {
		const img = new Image();
		img.onload = () => {
			const newImage = this.getThumbnail(img, 0.15);
			this.screenshotSrc = newImage.toDataURL('image/jpeg', 1.0);
		};

		const fullScreenDataKey = this.captureSupportService.getFullScreenKey(data);
		const fullScreenDataUrl = data[fullScreenDataKey];

		img.src = fullScreenDataUrl;
	}

	private getThumbnail(original, scale) {
		const canvas = document.createElement('canvas');
		canvas.width = original.width * scale;
		canvas.height = original.height * scale;
		canvas.getContext('2d').drawImage(original, 0, 0, canvas.width, canvas.height);

		return canvas;
	}
}
