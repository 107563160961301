import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
	selector: 'eup-generic-input',
	templateUrl: './input.component.html',
	styleUrls: [ './input.component.scss' ]
})
export class InputComponent {
	@Input() id: string;
	@Input() label: string;
	@Input() disabled: boolean;
	textValue: string;
	@Output() textChange = new EventEmitter<string>();

	@Input()
	get text() {
		return this.textValue;
	}

	set text(val) {
		this.textValue = val;
		this.textChange.emit(this.textValue);
	}
}
