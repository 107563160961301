import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild} from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { GlobalSettingsService } from '@core/globalSettings.service';

@Component({
	selector: 'confirm-reset-password-popup',
	templateUrl: './confirmResetPasswordPopup.component.html',
	styleUrls: ['./confirmResetPasswordPopup.component.scss']
})
export class ConfirmResetPasswordPopupComponent implements OnChanges {
	@ViewChild('confirmResetModal') confirmResetModal: ModalDirective;
	@Input() isSubmitting: boolean = false;
	@Input() show: boolean = false;
	@Output() resetPasswordEvent: EventEmitter<void> = new EventEmitter();
	
	loginEmail: string;

	constructor(private globalSettingsService: GlobalSettingsService) {
		this.loginEmail = this.globalSettingsService.get().loginEmail;
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['show'] && changes['show'].currentValue === true) {
			this.confirmResetModal.show();
		}
	}

	closeConfirmationModal() {
		this.confirmResetModal.hide();
	}

	resendResetPassword() {
		this.resetPasswordEvent.emit();
	}
}
